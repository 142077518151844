.container {
  display: flex;
  padding: 1rem .5rem;
  gap: 1rem;
  border-bottom: 2px solid #f4f4f4;
  @media only screen and (max-width: 576px) {
    padding: .75rem .5rem;
  }
  i {
    color: #2a976f;
    &::before {
      width: 50px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  .checkmark {
    font-size: 14px !important;
  }
  .contentContainer {
    a {
      cursor: pointer;
      text-decoration: underline;
      color: #386C87;
      font-weight: 600;
    }
    h4 {
      @media only screen and (max-width: 576px) {
        width: 85%; // Workaround for text overlapping modal close button on mobile
      }
    }
    span {
      font-size: 1.3rem;
      font-weight: 600;
    }
    ul {
      margin: auto;
      padding-left: 1.2rem;
      color: #47474a;
    }
  }

  .icon {
    margin-bottom: 2px;
    margin-right: 8px;
  }
}
.borderless {
  border-bottom: none !important;
}