@mixin minimal-scrollbar($width: 6px, $thumb-colour: rgba(#000, 0.2)) {
	&::-webkit-scrollbar {
		width: $width;
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-colour;
		border-radius: $width;
	}
}
