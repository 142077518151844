@import '../../../shared-styles/core-variables-and-helpers';

.outlineButton {
  font-size: 1.1rem;
  color: $green;
  border: 2px solid $green;
  background: transparent;
  border-radius: 4px;
  font-weight: 800;
  padding: 0.5rem 2rem;
  &:disabled {
    border-color: transparent;
  }
}
.divider {
  background: #9f9f9f;
}
.addonSectionTitle {
  margin-bottom: 0;
  font-size: 1.5rem;
  color: $grey-14;
}
.actionLink {
  text-decoration: underline;
}
.primary {
  color: $orange;
}

@media (max-width: 767px) {
  .sectionTitle {
    font-size: 1.3rem;
  }
}
