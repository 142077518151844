@import '../shared-styles/core-variables-and-helpers';


div.reduced-padding {
    padding-bottom: 0 !important;
}

h4.reminderTitle {
    background-color: #fff;
}

button.clear-icon {
    cursor: pointer;
    background-color: #0000;
    border: none;
    padding-top: 21px;
    outline: 0;
}

.deleteContainer {
    padding-top: 5px;
}

.reminderItem {
    .row {
        --bs-gutter-x: 1.5rem !important;        
    }
    margin-top: 10px;
    line-height: unset;
}

.reminderButtonWrapper {
    margin: auto;
    padding-top: 20px;
    padding-right: 10px;
}

.toggleTitle{
    padding: 0;
    margin-bottom: 5px;
}

.clear-icon {
    i {
        font-size: 20px;
        line-height: 2.3rem;
    }
}

.reminderTitle {
    margin-bottom: 5px;
    font-size: 16px;
}

.reminderBody {
    padding-bottom: 60px !important;
}

.reminderForm {
    margin: -40px 0;
}

.switchContainer {
    .col-pull-2 {
        margin-top: .25rem;
    }
}

.noMotBox {
    p {
        color: $orange-2;
        padding-left: 1rem;
    }
    position: absolute;
    border: 1px solid $orange-2;
    background-color: #fff;
    width: 100%;
    line-height: 3rem;
    bottom: 0!important;
    right: 0!important;    
}

@media only screen and (max-width: 767px)
{
    .reminderForm {
        margin: -25px 0;
    }

    .deleteContainer {
        padding: 0;
    }

    .dateMonth {
        padding-right: 0;
    }

    .toggleTitle{
        padding-left: 25px;
        font-size: 16px;
    }

    button.clear-icon {
        padding-top:27px;
    }

    .clear-icon {
        i {
            font-size: 25px;
        }
    }

    .reminderButtonWrapper {
        margin-top: 20px;
    }

    .reminderButtonWrapper {
        button {
            min-width: 120px;
        }
    }

    .switchContainer {
        margin-top: 1rem;
    }    
}
