.PaymentFailedPage {
    &__errorPage-header {
        color: #47474a;
        font-weight: normal;
        text-transform: none !important;
        font-size: 2rem;
        @media only screen and (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    &__errorPage-details-wrapper {
        display: flex;
        width: 100%;
        margin: 2em 0 2em 0;
        font-size: 1rem;
        @media only screen and (max-width: 576px) {
            flex-direction: column;
            margin: 1em 0 1em 0;
        }
        @media only screen and (min-width: 768px) {
            min-height: 296px;
        }
        @media only screen and (min-width: 992px) {
            min-height: 248px;
        }
    }

    &__errorPage-image {
        width: 350px;
        height: 350px;
        background: url('https://images.contentstack.io/v3/assets/bltab270fecbe9695fb/blt080210fb599f5f01/62582c9b02d8144fd4dab6a0/Maintenance_Desktop.png') no-repeat;
        @media only screen and (min-width: 768px) {
            display: flex;
            position: absolute;
            top: 160px;
            margin-left: 90px;
        }
        @media only screen and (min-width: 576px) and (max-width: 768px) {
            width: 300px;
            height: 300px;
            background-size: contain;
        }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            top: 200px;
            right: 100px;
        }
    }

    &__errorPage-image-wrapper {
        background-color: #ededed;
        @media only screen and (max-width: 576px) {
            display: flex;
            justify-content: center;
        }
        @media only screen and (min-width: 768px) {
            border-right: 300px solid #fff;
        }
    }

    &__errorPage-image-wrapper,
    &__errorPage-info-wrapper,
    &__back-button-container {
        width: 50%;
        @media only screen and (max-width: 576px) {
            width: 100%;
        }
      span {
        color: #f95108;
      }
    }

    &__errorPage-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #ededed;
        padding: 30px;
        height: auto;
        @media only screen and (min-width: 768px) {
            border-bottom: 60px solid #ededed;
        }
    }

    &__back-button-container {
        margin-bottom: 2em;
        button {
            text-align: left;
            padding: 1rem;
            border-radius: 0.2em;
        }
    }
}
