@import '../../../shared-styles/core-variables-and-helpers';

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 10px);
  padding: 5px 14px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  color: $white;
  background-color: $smr-blue;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    color: $white;
    text-decoration: none;
  }
  &:active {
    color: $white;
  }
  &:visited {
    color: $white;
  }

  p {
    position: relative;
    padding-left: 50px;
    margin: 0;
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.5rem;
    b {
      display: block;
    }
    img {
      display: inline-block;
    }
  }

  img {
    margin: 4px 0;
  }

  .leftIcon {
    position: absolute;
    top: 8px;
    left: 6px;
    display: block;
    width: 26px;
    margin: 0;
  }
}
