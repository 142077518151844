@media only screen and (max-width: 767px)
{
    /* Remove height on mobile*/

    .showNav {
        height: 100%;
        overflow: visible;
    }

    .navsub-menu {
        .breakdown-link {
          color: #F95108 !important;

          span:first-of-type {
            font-weight: bold;
          }
        }

        li{
            height: auto;
            margin: 0;
            padding: 0;
            vertical-align: middle;
            position: relative;
            border-bottom: 1px solid #eee;
            text-align: left;
            transition: initial;
            margin-right: 55px;
            border-right: 1px solid #eee;
        }

        ul{
            margin: 0;
            padding: 0;

        }

        > ul li > a{
            margin-right: 0px;
            padding: 12px 15px 12px 25px;
            display: block;
            color: #444;
        }

        > ul > li > span {
            width: 55px;
            height: 45px;
            display: block;
            position: absolute;
            top: 0;
            right: 0px;
            border-left: 1px solid #eee;
            transition: all .2s ease;

            &:after {
                font-family: racicon;
                font-weight: 400;
                font-variant: normal;
                font-size: 40px;
                line-height: 40px;
                text-transform: none;
                text-decoration: inherit;
                text-align: center;
                content: "\f107";
                text-rendering: auto;
                -ms-transform: translate(0,0);
                transform: translate(0,0);
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transition: all .2s ease;
                width: 55px;
                height: 40px;
                margin-top: -22px;
                position: absolute;
                top: 50%;
                right: 0;
                color: #ef6c00;
            }
        }
    }

    .navsub-menu > ul > li.open > span:after {
        -ms-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
        opacity: 1;
    }
    
    .siteNav > ul > li > span:after {
        content: '';
        background: url(../assets/images/button/down-arrow-orange.svg) no-repeat !important;
        width: 55px;
        height: 15px;
        position: absolute;
        margin: 21px 15px 15px 14px;        
    }

    .siteNav>ul>li>.navSub>div>div {
        padding: 0
    }



    .second-level{
        width: auto;
        height: 0;
        overflow: hidden;
        opacity: .46;
        transition: all 0s ease-in-out .32s;
        box-shadow: none;
        transition-delay: 0s;

        li{
            margin-right: 0px;
            border-top: 1px solid #e1e1e1;
        }

        > ul li > a {
            margin-right: 0px;
            padding: 9px 6px 12px 40px;
            display: block;
            background-color: #eee;
            color: #444;
        }
    }

    li.open>.second-level {
        max-height: 1400px;
        opacity: 1;
        height: initial;
    }
}
