@import 'shared-styles/core-variables-and-helpers';

.bannerWrapper {
  background-color: $orange;
  color: white;
  text-align: center;

  p {
    padding: 13px 25px;
    margin: 0;
    font: 400 1rem / 1.5rem $base-font-family;
  }
}

.banner {
  display: block;
  max-width: 100%;
  max-height: 180px;
  margin: 0 auto;

  @media only screen and (min-width: $tablet) {
    display: none;
    max-width: none;
  }
}

.bannerDesktop {
  display: none;

  @media only screen and (min-width: $tablet) {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 180px;
  }
}

.bannerLink {
  display: inline-block;
  margin: 10px 0;
  text-decoration: underline;
  color: white;

  img {
    margin-right: 8px;
  }
}