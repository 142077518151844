@import '../core-variables-and-helpers';

.Input {
	@include fluid-font-size(0.25);
	display: block;
	max-width: $input-width;
	$c: &;

	&--full-width {
		max-width: 100%;
	}

	&--inline {
		max-width: 100%;
	}

	&--inline & {
		&__input {
			max-width: 100%;
		}
	}

	&__label {
		position: relative;
	}

	&__field {
		position: relative;
		// This should be inline-block, but IE adds extra height into Input__label which breaks layout.
		display: table;
		width: 100%;
	}

	&__input {
		@include normal-weight;
		width: 100%;
		max-width: $input-width;

		display: block;

		height: $input-height;
		padding: 0.25em $input-element-right-padding 0.4em $input-element-left-padding;

		font-size: 1em;
		line-height: $input-line-height;

		background: $input-background-color;

		border-radius: $input-border-radius;
		border: $input-border-width $form-field-default solid;

		outline: none;
		appearance: none;

		&::-ms-clear {
			display: none;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--lowercase {
			text-transform: lowercase;
		}

		&--capitalize {
			text-transform: capitalize;
		}

		&--full-width {
			max-width: 100%;
		}

		&::placeholder {
			@include normal-weight;
			font-size: 0.9em;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}
	}

	&--select & {
		&__field {
			width: auto;
		}

		&__input {
			width: auto;
			min-width: 3em;
			padding-right: $input-element-right-padding;

			&::-ms-expand {
				display: none;
			}

			&:focus::-ms-value {
				background: none;
				color: inherit;
			}
		}
	}

	&__caret {
		position: absolute;
		right: 0.8em;
		bottom: 0.95em;
		transform: translateY(calc(-50% - #{calc($input-caret-height / 2)})) scaleY(1.2);

		width: $input-caret-width;
		height: $input-caret-height;

		pointer-events: none;
		z-index: 10;

		svg {
			position: absolute;
			top: 0;

			width: 100%;
			height: 100%;
		}
	}

	&__title {
		@include normal-weight;
		z-index: 20;
		position: relative;
		display: inline-block;
		bottom: 0;
		left: 0.35em;

		padding: 0 0.25em;

		pointer-events: none;
		transform-origin: left 0;
		line-height: 1em;
		transform: scale(1) translate(0.15em, $input-line-height);

		transition: transform $transition-fast, color $transition-fast;

		cursor: text;

		color: $grey-2;

		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 40%;
			opacity: 0;
			right: 0;
			bottom: $input-border-width;
			left: 0;

			background: $white;
		}
	}

	&__title-inner {
		position: relative;
	}

	&__suffix {
		position: absolute;
		left: 100%;
		top: 0.3em;
		min-width: 125px;
		padding-left: 10px;
		font-size: 0.88em;
		color: $grey-2;
	}

	&--filled &__title,
	&--focused &__title,
	&__input:active:not(:disabled) ~ &__title {
		transform: scale(0.875) translate(0.25em, 70%);
		cursor: default;

		&:before {
			opacity: 1;
		}
	}

	&--fixed & {
		&__title {
			transform: none;
			color: inherit;
		}

		&__field {
			width: initial;
		}
	}

	&--filled & {
		&__title {
			color: $grey;
		}
	}

	&__valid-indicator-image,
	&__invalid-indicator-image {
		height: 1em;
		width: 1.1em;
		position: absolute;
		right: 0.6em;
		bottom: 0.9em;
		display: none;
		pointer-events: none; // Ensure click events are applied to input instead of image, so that select inputs open when the image is clicked
	}

	&--focused & {
		&__input {
			border-color: $form-field-focus;
		}

		&__caret svg {
			fill: $form-field-focus;
		}

		&__title {
			color: $form-field-focus;
		}

		&__suffix {
			color: $form-field-focus;
		}
	}

	&--valid & {
		&__caret svg {
			display: none;
		}

		&__suffix {
			color: $form-field-valid;
		}

		&__valid-indicator-image {
			display: inline-block;
		}
	}

	&--focused &__input {
		border-color: $form-field-focus;
	}

	&--force-show-errors &__error {
		display: block;
	}

	&--invalid & {
		&__label {
			position: relative;
		}

		&__input {
			border-color: $form-field-invalid;
		}

		&__caret {
			display: none;
		}

		&__suffix {
			color: $form-field-invalid;
		}

		&__invalid-indicator-image {
			display: inline-block;
			z-index: 10;
		}

		&__error {
			display: block;
		}

		&__priority-msg {
			display: none;
		}
	}

	&--disabled &__input {
		background: $input-disabled-background-color;

		.iOS & {
			opacity: 0.8;
		}
	}

	&__error {
		@include medium-weight;
		display: none;
		line-height: 1;
		margin-top: 0.15em;
		font-size: 0.75em;
		margin-left: $input-element-left-padding;
		color: $form-field-invalid;
		opacity: 1;
		transition: opacity $transition-fast;
	}

	&__helper {
		@include brevier;
		margin-top: 0;
		color: $grey-2;
	}

	&__priority {
		position: relative;
		top: -2px;
		z-index: -1;
		font-size: .85em;
		padding: .4em .4em .4em .85em;
		background-color: $grey-4;
		color: $tuatara;

		&#{$c}__error {
			margin: 0;
			font-size: .85em;
			color: $form-field-invalid;
			padding: .4em .4em .4em .85em;
			font-weight: 300;
			line-height: inherit;
		}
	}
}
