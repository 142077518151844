@import '../../../shared-styles/core-variables-and-helpers';

.btnContainer {
  display: inline-block;
  width: 100%;
  max-width: 148px;
  min-width: 98px;
  padding: 6px;
  margin-left: auto;
  border-radius: 5px;
  outline: 0;
  border: 0;
  text-align: center;
  color: $white;
  background-color: $smr-blue;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);

  @media only screen and (max-width: 1060px) {
    margin-left: 0;
  }

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &:active {
    color: $white;
  }

  &:visited {
    color: $white;
  }

  &.inline {
    width: auto;
  }
}