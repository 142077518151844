.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    background-color: #F5F5F6;

    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    code {
        border-radius: 3px;
    }
}

.bs-callout + .bs-callout {
    margin-top: -5px;
}

.bs-callout-default {
    border-left-color: #777;

    h4 {
        color: #777;
    }
}

.bs-callout-primary {
    border-left-color: #ef6c00;

    h4 {
        color: #428bca;
    }
}

.bs-callout-success {
    border-left-color: #5cb85c;

    h4 {
        color: #5cb85c;
    }
}

.bs-callout-danger {
    border-left-color: #d9534f;

    h4 {
        color: #d9534f;
    }
}

.bs-callout-warning {
    border-left-color: #f0ad4e;

    h4 {
        color: #f0ad4e;
    }
}

.bs-callout-info {
    border-left-color: #5bc0de;

    h4 {
        color: #5bc0de;
    }
}
