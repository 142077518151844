@import '../../../shared-styles/core-variables-and-helpers';

.CollapsibleSection {
  &__section-title {
    font-size: 1.5rem;
    color: $grey-14;
    margin-bottom: 0;
  }
  &__toggle-button {
    border: none;
    background: transparent;
    padding: 0;
    color: $black;
  }
  &__chevron {
    width: 18px;
  }
  @include breakpoint(mobile-large, down) {
    &__section-title {
      font-size: 1.3rem;
    }
    &__opened &__content {
      display: block;
    }
    &__opened &__chevron {
      transform: rotate(180deg);
    }
    &__closed &__content {
      display: none;
    }
  }
}
