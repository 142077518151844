@mixin light-weight {
	font-weight: 100;
	letter-spacing: -0.015em;
}

@mixin normal-weight {
	font-weight: 300;
}

@mixin medium-weight {
	letter-spacing: -0.02em;
	font-weight: 600;
}

@mixin bold-weight {
	font-weight: bold;
	letter-spacing: -0.03em;
}
