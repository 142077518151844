$modal-padding-small: $collapse-padding-small;
$modal-padding-large: $collapse-padding-large * 2;
$mobile-modal-margin: 15px;

$modal-mask-opacity: 0.8;
$modal-cross-size: 30px;
$modal-cross-offset: 10px;

.Modal {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: 1000px;
	max-height: 100%;
	flex: 0 1 auto;

	background: $white;
	border: 0;
	border-radius: 7px;
	overflow: hidden;

	.Samsung-Browser & {
		// DE8452 In the Samsung browser, the modal shrinks when displayed, or when rotating the tablet. To avoid this we
		// force the modal to take up the whole height.
		flex: 1 1 100%;
	}

	.Mobile-Safari & {
		margin: $mobile-modal-margin 0;
	}

	.mobile.Android & {
		margin: $mobile-modal-margin 0;
	}

	.IE & {
		// DE8448 In IE10 and IE11, containers with display: flex and flex-direction: column will not properly calculate
		// their flexed childrens' sizes if the container has min-height but no explicit height property
		// https://connect.microsoft.com/IE/feedback/details/802625/min-height-and-flexbox-flex-direction-column-dont-work-together-in-ie-10-11-preview
		height: 100%;
		margin: $mobile-modal-margin 0;
	}

	&__cross {
		display: none;
	}

	&__header {
		@include padding-vertical(15px, 70px);
		@include padding-horizontal(15px, 60px);

		position: relative;

		background: #6c99ae url(../../assets/images/button/modal-gradient.png) no-repeat right top;
		background-size: 100% 100%;
	}

	&__header-image {
		@include fluid-property-vertical(height, 90px, 150px);
		@include fluid-property-vertical(bottom, -10px, -15px);

		display: none;
		position: absolute;
		max-width: 300px;
		right: 40px;
	}

	&__body {
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 1000px;
		max-height: 100%;
		flex: 0 1 auto;
		min-height: 0;

		overflow: hidden;

		&::-webkit-scrollbar-button {
			@include fluid-property(height, calc($modal-padding-small / 2), calc($modal-padding-large / 2));

			display: block;
		}

		.IE & {
			flex: 0 1 100%;
		}
	}

	&__footer {
		@include padding-top($narrow: true);
		@include padding-bottom;
		@include padding-horizontal($modal-padding-small, $modal-padding-large);

		position: relative;
		flex-shrink: 0;
	}

	&__close {
		display: block;
		width: 100%;
	}

	&__mask {
		@include fluid-property-vertical(height, $modal-padding-small, $modal-padding-large);

		z-index: 1;
		flex-shrink: 0;

		&--top {
			background: linear-gradient(rgba($white, $modal-mask-opacity), rgba($white, 0));
		}

		&--bottom {
			background: linear-gradient(rgba($white, 0), rgba($white, $modal-mask-opacity));
		}
	}

	&__inner {
		@include margin-vertical(-$modal-padding-small, -$modal-padding-large);
		@include padding($modal-padding-small, $modal-padding-large);
		@include minimal-scrollbar;

		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		color: $grey;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		strong {
			color: $dark-grey;
		}

		&::-webkit-scrollbar-button {
			@include fluid-property-vertical(height, calc($modal-padding-small / 2), calc($modal-padding-large / 2));

			display: block;
		}

		ul {
			padding-left: 1.25em;
		}

		li:not(:last-child) {
			margin-bottom: 0.5em;
		}
	}

	&__cta-container {
		display: flex;
		align-items: center;
	}

	&__price {
		@include medium-weight;

		display: flex;
		align-items: flex-end;

		margin-right: 20px;

		font-size: 2.2em;
		line-height: 1em;
	}

	&__frequency {
		@include brevier;

		align-self: flex-end;
		margin: 0 0 0.25em 0.5em;

		max-width: 2.55em;

		line-height: 1;
		font-weight: 300;
	}

	&__btn {
		align-self: center;

		&--desktop {
			display: none;
		}
	}

	@include breakpoint(tablet, up) {
		&__header-image {
			display: block;
		}

		&__cross {
			@include button-reset;

			z-index: 2;
			position: absolute;
			top: calc($modal-cross-size / 2);
			right: calc($modal-cross-size / 1.25);

			width: $modal-cross-size;
			height: $modal-cross-size;
			padding: 0;

			background: url(../../assets/images/button/cross-grey.svg) center center no-repeat;
			background-size: 50%;
		}

		&__btn {
			min-width: 8.889em;
			font-size: 1.125em;

			&--mobile {
				display: none;
			}

			&--desktop {
				display: inline-block;
			}
		}

		&__price {
			font-size: 3.25em;
		}

		&__column {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding-right: 15px;

			+ .Modal__column {
				margin-left: -4px;
				padding-right: 0;
				padding-left: 15px;
			}
		}
	}

	// Modifiers
	&--close-button {
		@include breakpoint(tablet, up) {
			padding-top: $modal-cross-offset + $modal-cross-size;
		}
	}

	&--close-button & {
		&__inner {
			@include breakpoint(tablet, below) {
				padding-bottom: 0;
			}
		}
	}

	&--landing-page {
		display: none;
		padding: $modal-cross-size 0 0;

		overflow: hidden;
		opacity: 0;

		transform: translateY(100%);
		transition: opacity 0.325s $ease-in-out-back, transform 0.325s $ease-in-out-back;

		&::-webkit-scrollbar-button {
			display: none;
		}

		&[open] {
			display: flex;
		}
	}

	&--landing-page#{&}--open {
		opacity: 1;
		transform: translateY(0);
	}

	&--landing-page & {
		&__body {
			@include padding-horizontal(15px, 30px);
		}

		&__footer {
			display: flex;
			flex-direction: row-reverse;

			padding: 15px;

			background-color: #efefef;
		}

		&__inner {
			@include padding-top(15px, 50px);
			@include padding-bottom(15px, 50px);
			padding-right: 0;
			padding-left: 0;
		}

		&__cross {
			@include button-reset;

			z-index: 2;
			position: absolute;
			top: $modal-cross-offset;
			right: $modal-cross-offset;
			width: $modal-cross-size;
			height: $modal-cross-size;
			padding: 0;

			background: url(../../assets/images/button/cross-grey.svg) center center no-repeat;
			background-size: 50%;
		}
	}
}
