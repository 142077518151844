.row.display-flex {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    margin-bottom: 20px;
}
.row.display-flex > [class*='col-'] {
    flex: 1;
}

.box {
    height: 100%;
}

/* Advetorial panel*/

p.box-height{
    min-height: 70px;
}
.box-height + p{
    padding: 0;
}

/*Modal height for header*/
.modal.myrac .modal-header {
    padding: 20px 0;
}

/* Removed from original css*/
@media (max-width:400px) {
    .more4more__product-icon img {
        width: 20px
    }
    .more4more__product-details {
        padding: 10px 90px 10px 40px
    }
}

/* ipad etc. */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    p.box-height {
        min-height: 120px;
    }
    div.display-adve-flex
    {
        margin: 10px 0;
    }
}

/* mobile etc. */

@media only screen and (max-width: 767px)
{
    .MyRacForm__button {
        margin-left: 3px;
        margin-right: 2px;
        margin-top: 5px;
    }

    .box{
        min-height: 120px;
    }
    .row.display-flex
    {
        display: inherit;
        margin-bottom:10px;
    }

    .row.display-flex > [class*='col-'] {
        padding-bottom: 0px;
    }

    .modal{
        overflow-x: hidden;
        overflow-y: auto;
    }


}
