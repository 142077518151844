@import '../../../../../shared-styles/core-variables-and-helpers';

.MembershipReminders {
  $wrapper: &;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: px-to-rem(170px);
  padding: 0;
  position: relative;

  &__content-container {
    padding: px-to-rem(17px);
  }

  &__button-container {
    position: absolute;
    bottom: 0;
    padding: px-to-rem(17px);

    @include breakpoint(bootstrap-small, down) {
      position: revert;
    }
  }

  & > Button {
    position: relative;
    bottom: px-to-rem(17px);
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
  }

  [class^='Button_Btn'] {
    margin-top: auto;

    @include breakpoint(mobile-large, down) {
      text-align: left;
    }
  }

  &__title {
    font-size: px-to-em(20px);
    margin: 0 0 px-to-rem(15px) 0;

    @include breakpoint(bootstrap-small, down) {
      display: none;
    }
  }

  &__reminders {
    display: flex;
    flex-direction: column;

    @include breakpoint(bootstrap-small, down) {
      padding-bottom: px-to-em(25px);
    }
  }

  &__reminder {
    font-size: px-to-em(15px);
  }

  &__reminder:last-of-type {
    padding-bottom: 0;
  }

  &__date {
    color: $black;
    &--warning {
      color: $red;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
