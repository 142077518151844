@import '../shared-styles/core-variables-and-helpers';

.vehiclebox{
    padding-left: 25px;
}
.left-content{
    padding: 0;
    text-align: center;

    i {
        font-size: 22px;
    }
}

.right-content {
    margin-bottom: 10px;
    padding: 0 10px;

    p{
        padding: 0;
        margin-bottom: 10px;
    }

    p.title
    {
        font-size: 18px;
    }

    p.board
    {
        font-size: 1.2em;
    }
}

p.vrm-name
{
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 0px;
}

p.vrm-makemodel
{
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 0px;
    color: #777;
}

.vrm-green
{
    font-weight: 200;
    font-size: 90%;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 2px 10px 2px 6px;
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    display: inline-block;
    width: 7em;
    position: relative;
    background-color: #1E9771;
    color: #fff;
}

.vrm-text-green
{
    font-weight: 900;
    color: #1E9771;
    margin-left: 10px;
    margin-bottom: 0px;
}

.vrm-yellow
{
    font-weight: 200;
    font-size: 90%;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 2px 10px 2px 6px;
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    display: inline-block;
    width: 7em;
    position: relative;
    background-color: #FFE997;
    color: #000;
}

.vrm-text-yellow
{
    font-weight: 900;
    color: #47474A;
    margin-left: 10px;
    margin-bottom: 0px;
}

.vrm-red
{
    font-weight: 200;
    font-size: 90%;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 2px 10px 2px 6px;
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    display: inline-block;
    width: 7em;
    position: relative;
    background-color: red;
    color: #fff;
}

.vrm-text-red
{
    font-weight: 900;
    color: red;
    margin-left: 10px;
    margin-bottom: 0px;
}

.tile .date-highlight
{
    font-size: 15px;
}

.add-vehicle
{
    text-align: center;
    margin: 20px 0;

    button
    {
        min-width: 175px;
    }

    & > span {
        font-size:80%;
    }
}
