@import '../../../../shared-styles/core-variables-and-helpers';

.overflow {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: 1001;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #222;
  opacity: 0.5;
  z-index: 1000;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;

  button {
    font-size: 44px;
  }
}

.container {
  position: relative;
  opacity: 1;
  display: block;
  max-width: 734px;
  padding: 24px;
  margin: 1.75rem auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 9px #333;
  background-clip: padding-box;
  outline: 0;

  @media only screen and (max-width: 1024px) {
    max-width: 576px;
    margin: 1.75rem auto;
  }

  @media only screen and (max-width: 640px) {
    margin: 1.75rem 1rem;
  }
}

.title {
  padding: 0 32px;
  margin: 4px 0 24px;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 3.25rem;
  text-align: center;
  color: $grey-14;

  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $grey-14;
}