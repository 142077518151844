@import '../shared-styles/core-variables-and-helpers';

section {
  padding: 0 !important; //Needed to overwrite the padding coming from rac.min.css file
}

.display__none {
    display: none;
}

.cursor__pointer {
    cursor: pointer;
}

.tactical-garage-desktop {
    padding-top: 20px;
    float: right;
}
.fullwidth {
    width: 100%;
}

.card-widget-text {
    padding-top: 49.2% !important; //Needed to overwrite the padding-top coming from rac.min.css file
}

.free-product-upgrade {
    background-color: $green;
    margin: 0 -1px 40px -1px;

    @include breakpoint(bootstrap-small, down) {
        margin-bottom: 15.67px;
    }

    &__product-description {
        margin-bottom: 0px !important;  // !important is used here and below as there is a need to overwrite the rac.min.css file
        padding: 19px 12px !important;
        text-align: center;
        @include fluid-property('font-size', 16px, 21px);
        @include breakpoint(bootstrap-small, down) {
            padding: 15px 14px !important;
        }

        p {
          color: $white;
          font-weight: 400;
          margin-bottom: 0px !important;
          padding: 0px !important;
        }
    }
}

.breakdown-cover-header {
    margin-bottom: 14px !important; //Needed to overwrite the padding-top coming from rac.min.css file
}

/* Package changes*/
.package-promo {
    position: relative;
    height: auto;
    border-radius: 10px;
    margin: 18px 0;
    border: 2px $orange solid;
}

/* M4M changes*/

.freeProducts {
    span {
        color: $grey;
        font-weight: 600;
    }
}

.cover-options {
    p.desc {
        margin-bottom: 8px;
    }
    .row.yes {
        .free {
            &::after {
                position: absolute;
                top: 50%;
                right: -53px;
                margin-top: -13px;
                content: 'Free';
                color: $white;
                width: 25px;
                height: 25px;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

/* Remove arrow from number input */
input[type=number] {
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.btn-space {
    margin-right: 5px;
}
.btn-secondary-myrac {
    background-color: $white;
    color: $orange-2;
    border: 2px solid $orange-2;
    &:hover {
        background-color: $white;
        color: $orange-2;
        border: 2px solid $orange-2;
    }
    &:active {
        background-color: $white;
        color: $orange-2;
        border: 2px solid $orange-2;
    }
    &:visited {
        background-color: $white;
        color: $orange-2;
        border: 2px solid $orange-2;
    }
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.no-padding {
    padding: 0;
}
@media only screen and (max-width: 767px) {
    .membership-button-wrapper {
        .btn-secondary-myrac {
            margin: 15px 0;
        }
    }
}
