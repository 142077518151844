.sliderContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-bottom: 38px;
}

.sliderItem {
  position: relative;
  min-height: 334px;

  img {
    width: 100%;
    max-width: 576px;
  }
}

.sliderItemCaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
}

.tns-outer {
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  [hidden] { display: none !important; }
  [aria-controls], [data-action] { cursor: pointer; }
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  &.tns-no-subpixel {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > .tns-item {
      float: left;
    }
  }
  &.tns-carousel {
    &.tns-no-subpixel {
      > .tns-item {
        margin-right: -100%;
      }
    }
  }
}

.tns-visually-hidden { position: absolute; left: -10000em; }

// *** Fix a viewport issue in initialization
.tns-vpfix {
  white-space: nowrap;
  > div, > li {
    display: inline-block;
  }
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }
  &-ct {
    width: (100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > div {
      width: (100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
}

.tns-nav {
  position: absolute;
  bottom: 20px;
  width: 100%;

  button {
    width: 8px;
    height: 8px;
    padding: 0;
    margin: 0 2px;
    border: 0;
    border-radius: 20px;
    background-color: #47474A;
    transition: 0.4s linear all;
  }

  .tns-nav-active {
    width: 24px;
    height: 8px;
    padding: 0;
    border: 0;
    border-radius: 20px;
    background-color: white;
  }
}