$toggle-button-width: 1.5em;

.Toggle {
	display: inline-block;
	position: relative;
	text-align: left;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: $toggle-button-width;
		height: 100%;

		border-radius: calc($toggle-button-width / 2);
		background: rgba($white, 0.3);

		transition: transform $transition-fast;
	}

	&__input {
		@include outline-reset;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: $toggle-button-width;
		height: 100%;
		opacity: 0;

		cursor: pointer;

		&:not(:checked) {
			z-index: 2;
		}
	}

	.tabbing &__input:focus ~ &__indicator {
		border: 2px solid $form-field-focus;
	}

	&__indicator {
		position: absolute;
		top: 0;
		left: 0;
		width: $toggle-button-width;
		height: $toggle-button-width;
		background: $white;
		border-radius: 50%;

		pointer-events: none;

		transition: transform $transition-fast;
	}

	&__input:checked + &__label + &__indicator {
		transform: scale(1) translateY(1.6em);
	}

	&__label {
		@include medium-weight;
		display: block;
		margin-left: $toggle-button-width + 0.5em;
		line-height: 1.6em;

		cursor: pointer;
		user-select: none;
	}
}
