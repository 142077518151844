@import '../../../../../shared-styles/core-variables-and-helpers';

.membershipCoverContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: space-between;
    column-gap: 20px;
  }

  .title {
    color: $orange;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0px;
    width: 50%;
    padding: 0px 10px;

    .items {
      .row {
        .coveredList {
          padding-left: 0.8rem;
          margin-top: 10px;

          @include breakpoint(bootstrap-small, down) {
            line-height: 1.8;
          }

          .benefit {
            font-size: 13px;
            font-weight: bold;
            color: $grey;
          }
        }
      }
    }

    .upgradeCoverButton {
      @media only screen and (max-width: 992px) {
        width: 100%;

        Button {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .extrasContainer {
    .list {
      padding: 0;
      margin-top: 0.5em;
      column-count: 2;
      column-gap: 0;

      @include breakpoint(bootstrap-small, down) {
        margin-top: 0.6em;
      }

      .item {
        background-image: url('../../../../../assets/images/button/tick-outline-green.svg');
        background-repeat:no-repeat;
        list-style-type: none;
        -webkit-column-break-inside: avoid; // Chrome, Opera and Safari
        page-break-inside: avoid; // For Firefox
        @include breakpoint(bootstrap-small, down) {
          background-size: 1.25em;
        }

        .inner {
          padding: 0 0 0 18px;
          font-size: 13px;
          @include breakpoint(bootstrap-small, down) {
            padding-left: 22px;
          }
        }
      }
    }
  }
}
