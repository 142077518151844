@import 'shared-styles/core-variables-and-helpers';

.header {
  display: block;
  margin: 0 auto;

  @media only screen and (max-width: 576px) {
    width: 90% !important;
    padding-top: 5vw;
  }

  .title {
    font-size: 2rem;
  }

  .price {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
}

.container {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 1vw;
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    width: 100%;
  }

  .info, .payframe {
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    font-size: 1rem;
    @media only screen and (max-width: 576px) {
      width: 90% !important;
    }
  }

  .info {
    width: 60%;
    padding-right: 30px;
    @media only screen and (max-width: 576px) {
      padding-right: 0;
      padding-bottom: 4vw;
    }

    .greybox {
      position: relative;
      height: 470px;
      background-color: $grey-f8;
      padding: 30px;
      border-radius: 10px;
      @media only screen and (max-width: 576px) {
        height: auto;
        padding-bottom: 120px;
        div {
          width: 50%;
        }
      }

      div {
        width: 25%;
      }
      span {
        &:hover {
          cursor: pointer;
        }
        color: #f95108;
        line-height: 1.5rem;
      }
      img {
        width: 150px;
        mix-blend-mode: multiply;
      }
      .date {
        position: absolute;
        padding: 30px;
        bottom: 0;
        left: 0;
      }
    }
  }

  .payframe {
    width: 40%;
    @media only screen and (max-width: 576px) {
      padding-bottom: 4vw;
    }
  }

  .clear {
    clear: both;
  }
}

.compactVersionContainer {
  max-width: 80vw;
  padding: 24px;
  margin: 20px auto 60px;
  background-color: $grey-f8;
  @media only screen and (max-width: 576px) {
    max-width: calc(100% - 20px);
  }
  h3 {
    font-size: 1.7rem;
  }
  img {
    margin-bottom: 32px;
  }
  .info {
    padding-left: 16px;
    margin: 0 auto 40px;
    font-size: 1rem;
    @media only screen and (max-width: 576px) {
      width: 90% !important;
    }

    p {
      font-weight: bold;
    }

    span {
      color: #f95108;
      line-height: 1.5rem;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
