.background {
  margin-top: 2px;
}

.wrapper {
  display: flex;
  max-width: 1190px;
  font-size: 1rem;
  color: #47474a;
  justify-content: flex-start;
  gap: 60px;
  padding-top: 2vw;
  @media only screen and (max-width: 576px) {
    font-size: 1.2rem;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 1.5rem;
    gap: 0px;
    padding-bottom: 10vw;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  .content {
    display: flex;
    max-width: 716px;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    
    p {
      margin-bottom: 0 !important;
    }

    h2 {
      font-size: 1.5rem;
    }
  
    .title {
      font-weight: 600;
      font-size: 2rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .description {
      margin-top: -1rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }

    .cover, button {
      i {
        color: #2a976f;
      }
      i.selected {
        color: #fff !important;
      }
      border: none;
      padding: 1.25rem;
      @media only screen and (max-width: 576px) {
        padding: 1rem;
      }      
    }

    .cover {
      box-shadow: 0 2px 10px hsl(0deg 0% 46% / 30%);
      margin-bottom: 1rem;
    }

    .price {
      font-weight: 500;
      font-size: 2rem;
      span {
        color: #f95108;
        font-weight: 600;
      }
    }
    
    .icon {
      margin-bottom: 2px;
      margin-right: 8px;
    }  
  }
}