$btn-padding: 0.75em !default;
$btn-search-icon-width: 1.25em ;
$btn-chevron-icon-height: 0.75em !default;
$btn-chevron-icon-width: $btn-chevron-icon-height * calc(15 / 19) * 1.1 !default;
$btn-add-remove-icon-width: 1.4em;
$btn-full-width-max-width: 470px;

@mixin button-icon($icon, $width, $height: $width, $right: true) {
    &::after {
        content: "";
		    float: right;
        height: $height;
        width: $width;
        margin-left: 20px;
        transition: .2s;
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        transition: transform .2s ease;
        background-image: $icon;
    }
    &:hover {
        &::after {
            transform: translateX(3px);
        }
    }

}

.Btn {
	$c: &;
	@include button-reset;
	@include medium-weight;

	position: relative;
	padding: $btn-padding;

	background: $orange;
	color: $white;

	border-radius: 4px;

	transition: background-color $transition-fast, color $transition-fast, border-color $transition-fast;

	// Prevents elements moving when animating on hover. See: https://forum.webflow.com/t/a-fix-for-when-your-elements-move-up-and-or-blur-during-animations-interactions-on-chrome/11629
	backface-visibility: hidden;

	overflow: hidden;

	&__inner {
		transition: opacity $transition-normal;
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&--loading {
		&:after {
			opacity: 0;
		}
	}

	&--loading & {
		&__inner {
			opacity: 0;
		}

		&__loader {
			opacity: 1;
		}
	}

	&--block {
		display: block;
		width: 100%;
		text-align: center;
	}

	&--align-left {
		text-align: left;
	}

	&--search {
		@include button-icon(url(../../assets/images/button/search.svg), $btn-search-icon-width, $right: false);
	}

	&--chevron-right {
		@include button-icon(url(../../assets/images/button/chevron-right-white.svg), $btn-chevron-icon-width, $btn-chevron-icon-height);
	}

	&--chevron-left {
		@include button-icon(url(../../assets/images/button/chevron-left-white.svg), $btn-chevron-icon-width, $btn-chevron-icon-height, $right: false);

		&:disabled:after {
			background-image: url(../../assets/images/button/chevron-left-grey.svg);
			opacity: 0.4;
		}
	}

	&--circle {
		@include button-icon(url(../../assets/images/button/circle-green.svg), 1.4em, $right: false);
	}

	&--tick {
		@include button-icon(url(../../assets/images/button/tick-circle-white.svg), 1.4em, $right: false);
	}

	&--add,
	&--added,
	&--remove,
	&--removed {
		padding-left: $btn-padding * 2 + $btn-add-remove-icon-width;
		background: $white;
		color: $black;
		box-shadow: 0 2px 10px 0 rgba($grey, 0.3);
		text-align: left;

		svg {
			display: inline-block;
			position: absolute;
			top: 50%;
			left: $btn-padding;
			transform: translateY(-50%);
			width: $btn-add-remove-icon-width;
			height: $btn-add-remove-icon-width;

			fill: currentColor;
		}
	}

	&--add {
		svg {
			transition: fill $transition-fast;
		}

		svg {
			fill: $green;
		}

		.has-hover &:hover:not(:disabled) svg {
			fill: $white;
		}
	}

	&--added,
	&--removed {
		background: $green;
		color: $white;
	}

	&--shadow {
		box-shadow: 0 2px 10px 0 rgba($grey, 0.3);
	}

	&--blue {
		background: $blue;
	}

	&--green {
		background: $green;
	}

	&--white {
		background: $white;
		color: $grey;
	}

	&--grey {
		background: $grey;

		&#{$c} {
			&--gradient {
				background: linear-gradient(to bottom, $grey-7 0%, $grey 100%);
			}
		}
	}

	&--light-grey {
		background: $grey-4;
		border-color: $grey-2;
		color: $black;

		&#{$c} {
			&--chevron-right:after {
				background-image: url(../../assets/images/button/chevron-right.svg);
			}

			&--chevron-left:after {
				background-image: url(../../assets/images/button/chevron-left-black.svg);
			}
		}
	}

	&--gradient {
		background: linear-gradient(to bottom, $orange-2 0%, $orange-2 100%);
	}

	&--invalid {
		background: transparent;
		border-color: $form-field-invalid;
		color: $form-field-invalid;
		box-shadow: 0 0 0 1px $form-field-invalid;
	}

	&--full-width {
		width: 100%;
		// max-width: $btn-full-width-max-width; //DD -896 Remove once live
	}

	&:disabled {
		background: $grey-3;
		border-color: $grey-3;
		color: $grey-2;
		cursor: not-allowed;

		&#{$c}--added,
		&#{$c}--removed {
			background: $green;
			color: $white;
		}

		&#{$c}--add,
		&#{$c}--remove {
			background: $white;
			color: $black;
		}
	}

	&:disabled & {
		&__loader-chevron {
			background-image: url(../../assets/images/button/chevron-right-grey.svg);
		}
	}

	.has-hover &:hover:not(:disabled) {
		background: $orange-2;
		border-color: $orange-2;
		color: $white;
	}

	.has-hover &:hover:not(:disabled)#{&} {
		&--blue {
			background: lighten($blue, 5%);
			border-color: lighten($blue, 5%);
		}

		&--green {
			background: lighten($green, 5%);
			border-color: lighten($green, 5%);
		}

		&--white {
			background: lighten($grey-3, 12%);
			border-color: $grey;
			color: $grey;
		}

		&--grey {
			background: lighten($grey, 10%);
			border-color: lighten($grey, 10%);
		}

		&--light-grey {
			background: lighten($grey-4, 10%);
			border-color: lighten($grey-2, 10%);
			color: $black;
		}

		&--gradient {
			background: linear-gradient(to bottom, lighten($orange-2, 5%) 0%, lighten($orange-2, 5%) 100%);

			&#{$c} {
				&--grey {
					background: linear-gradient(to bottom, lighten($grey-7, 5%) 0%, lighten($grey, 5%) 100%);
				}
			}
		}

		&--chevron-right:after {
			transform: translate(25%, -50%);
		}

		&--chevron-left:after {
			transform: translate(-25%, -50%);
		}

		&--add,
		&--added,
		&--remove,
		&--removed {
			background: $green;
			color: $white;
		}
	}

	.tabbing &:focus {
		box-shadow: 0 0 0 3px $form-field-focus;
	}

	strong {
		@include bold-weight;
	}
}
