@import '../../../../shared-styles/core-variables-and-helpers';

.myrac__checkbox--input.autorenew:disabled+.myrac__checkbox--span{
  background-color: $grey-10 !important;
  border-color: $grey-14 !important;
}

.account-preferences__button-wrapper button:disabled {
  background: #A3A3A6 !important;
  border-color: #A3A3A6  !important;
}

.description {
  color: $grey-14 !important;

  a {
    color: $grey-14 !important;
    text-decoration: none;
  }
}

.warning {
  color: #F95108 !important;
  font-weight: bold;

  a {
    color: #F95108 !important;
    text-decoration: underline;
  }
}
