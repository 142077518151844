.ctaImage {
    height: 100%;
    width: 100%;
    position: relative;

    &__image-cta-tile {
      height: 100%;
      width: 100%;
      display: block;
      position: relative;
    }
}
