@import '../../../shared-styles/core-variables-and-helpers';

.container {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  background-color: $white;

  &:first-child{
    margin-bottom: 0px;
  }
  &:last-child {
    margin-top: 0px;
  }

  .iconTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: 60px;
    }

    .title {
      font-weight: bold;
      font-size: 1.2rem;
      margin: 0;
    }
  }

  .description, .additionalDescription {
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;    
  }
  
  .transparent-panels {
    background-color: transparent;
  }
}
