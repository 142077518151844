@import 'src/shared-styles/core-variables-and-helpers';

.listContainer {
  display: flex;
  height: 100%;
  float: none;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;

  .firstElement {
    display: none;
    @media only screen and (max-width: 760px) {
      display: block;
    }
  }

  .navListItemClicked {
    border-top: 5px solid $orange-3;
  }

  .navListItem {
    height: 100%;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 795px) {
      flex-direction: column;
      height: auto;
      min-height: 50px;
      width: 100%;
      align-items: center;
    }

    &:hover {
      background-color: #eee;
      color: #222;
    }

    .subNavItem {
      a {
        &:hover {
          color: $orange-3
        }
      }
    }
  }

  .subNavClicked {
    height: auto;
    opacity: 1;
    z-index: 999;
    transition: all 0s ease-in-out;
  }

  .subNav {
    width: 100%;
    height: 0;
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 998;
    overflow: hidden;
    opacity: .46;
    background: #eee;
    text-align: left;
    transition: all 0s ease-in-out .32s;
  }

  .navSubFirst {
    width: 990px;
    margin: 30px auto;
    padding: 0;
    position: relative;

    .navSubMenu {
      width: 66.66%;
      float: left;

      &:before {
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        background: #d5d5d5;
        content: '';
      }

      .navSubMenuList {
        width: 50%;
        margin: 0 0 -10px;
        padding: 10px 40px 0 0;
        display: block;
        float: left;
        list-style: none;
      }
    }
  }

  .parentButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #444;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    span {
      font-size: 1rem;
      font-weight: 600;
    }

    @media only screen and (max-width: 1119px) {
      font-size: 95%;
      min-height: 50px;
    }

    .arrowImage {
      width: 8px;
      height: 5px;
      margin-left: 5px;
      transition: all .34s ease;

      @media only screen and (max-width: 760px) {
        display: none;
      }

    }

    .arrowImageTransition {
      transform: rotate(180deg);
    }
  }
}


