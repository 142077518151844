@import '../../../shared-styles/core-variables-and-helpers';

.container {
  display: flex;
  flex-direction: row;
  flex: 50%;
  margin: 0 0 30px;
  @media only screen and (max-width: 600px) {
    align-items: center;
  }
  .icon {
    width: 30px;
    margin-right: 15px;
    text-align: center;
  }
  .infoContainer {
    width: 100%;

    .title {
      color: $grey-14;
      font-size: 1.2em;
      font-weight: 600;
      margin: 0;
    }
    .text {
      margin: 0;
    }
    .button {
      padding: 0;
    }
  }
}

.expired {
  color: $red;
}

