.container {
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  padding: 20px;

  .telephoneNumber {
    color: #f95108;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    .button {
      width: 150px;
    }
  }
}
