@import 'shared-styles/core-variables-and-helpers';

$option-transition: all 0.3s linear;

.wrapper {
  padding: 20px 25px;
}

.header {
  margin-bottom: 20px;
  text-align: center;
  font: 700 1rem / 1.5rem $base-font-family;
  color: $orange;
}

.options {
  background-image: url('/assets/images/icons/check-green-tick.svg'); // Preload green tick image
  background-size: 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  @media only screen and (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.title {
  display: inline-block;
  padding: 9px 14px 4px 14px;
  margin-bottom: 7px;
  border-radius: 60px;
  border: 3px solid $grey-14;
  font: 900 2.1rem / 2.1rem $base-font-bold-variant;
  transition: $option-transition;
  color: $grey-14;

  @media only screen and (max-width: 360px) {
    font: 900 1.5rem / 1.5rem $base-font-bold-variant;
  }
}

.subTitle {
  display: block;
  margin-bottom: 5px;
  font: 700 1.4rem / 1.6rem $base-font-family;
  transition: $option-transition;
  color: $grey-14;

  @media only screen and (max-width: 360px) {
    font: 700 1.2rem / 1.2rem $base-font-family;
  }
}

.description {
  margin-bottom: 0;
  font: 400 1rem / 1.5rem $base-font-family;
  white-space: pre-line;
  color: $grey-14;
}

.extraLabel {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  font: 600 0.9rem / 1.25rem $base-font-family;
  background-color: $orange;
  color: white;
  transition: $option-transition;
}

.option {
  position: relative;
  display: block;
  width: 100%;
  max-width: none;
  padding: 16px 13px 16px 73px;
  border: 2px solid transparent;
  border-radius: 6px;
  outline: 0;
  box-shadow: 0px 2px 10px 0px #00000026;
  background-color: white;
  background-image: url('/assets/images/icons/check-empty-circle.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: 25px 31px;
  cursor: pointer;
  text-align: left;
  transition: $option-transition;

  &:first-child {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: $tablet) {
    width: auto;
    max-width: 320px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  &.isExtra {
    padding-top: 50px;
    border: 2px solid $orange;
    background-color: $orange-5;
    background-position: 25px 64px;

    .title {
      border-color: $orange;
      background-color: $orange;
      color: white;
    }

    &.isChosen {
      .title {
        background-color: $green;
        color: white;
      }

      .extraLabel {
        background-color: $green;
      }
    }

    &.greyedOut {
      border: 2px solid $grey-18;

      .title {
        background-color: $grey-18;;
        color: white;
      }
    }
  }

  &.isChosen {
    border: 2px solid $green;
    background-color: $green-4;
    background-image: url('/assets/images/icons/check-green-tick.svg');

    .title {
      border-color: $green;
      background-color: $green-4;
      color: $green;
    }

    .subTitle {
      color: $green;
    }
  }

  &.greyedOut {
    background-color: white;
    color: $grey-18;

    .title {
      border-color: $grey-18;
    }

    .extraLabel {
      background-color: $grey-18;
      color: white;
    }
  }
}

.btnWrapper {
  display: none;

  @media only screen and (min-width: $tablet) {
    display: block;
    padding: 10px 26px;
  }

  p {
    margin-bottom: 20px;
    font: 400 0.85rem / 1.25rem $base-font-family;
  }
}

.spendPointsBtn {
  width: 100%;
  padding: 18px 16px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  font: 700 1.5rem / 1.75rem $base-font-family;
  background: $grey-15;
  color: $grey-18;
  transition: $option-transition;

  &:not([disabled]) {
    background-color: $green;
    color: white;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.spendPointsFloatingBar {
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  padding: 17px 20px;
  outline: 0;
  border: 0;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -1px 4px 0px #00000040;
  background-color: white;
  text-align: center;

  @media only screen and (min-width: $tablet) {
    display: none;
  }

  p {
    margin: 0 0 10px;
    font: 400 0.9rem / 1.25rem $base-font-family;
  }

  button {
    width: 90%;
    max-width: 310px;
    min-width: 220px;
    padding: 16px;
    border-radius: 4px;
    outline: 0;
    border: 0;
    font: 700 1.5rem / 1.75rem $base-font-family;
    transition: $option-transition;

    &:not([disabled]) {
      background-color: $green;
      color: white;
    }

    &[disabled] {
      background: $grey-15;
      color: $grey-18;
      cursor: not-allowed;
    }
  }
}