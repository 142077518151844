@import 'shared-styles/core-variables-and-helpers';

.wrapper {
  margin: 1rem 0;

  svg {
    width: 6px;
    margin-right: 5px;
    margin-bottom: 2px;
    color: $grey-14;
  }

  .button {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $grey-14;
  }
}
