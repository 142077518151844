@mixin padding-top(
	$small: $collapse-padding-small, 
	$large: $collapse-padding-large, 
	$vertical: $vertical-padding-margin-based-off-vh, 
	$narrow: false, 
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip-low-vertical: $default-fluid-property-clip-low-vertical,
	$clip-high-vertical: $default-fluid-property-clip-high-vertical, 
	$clip: true, 
	$clip-at-start: true, 
	$clip-at-end: true
) {
	$divider: 1;

	@if $narrow {
		$divider: 2;
	}

	@if $vertical {
		@include fluid-property-vertical(padding-top, calc($small / $divider), calc($large / $divider), $clip-low-vertical, $clip-high-vertical, $clip, $clip-at-start, $clip-at-end);
	} @else {
		@include fluid-property(padding-top, calc($small / $divider), calc($large / $divider), $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
	}
}

@mixin padding-bottom(
	$small: $collapse-padding-small, 
	$large: $collapse-padding-large, 
	$vertical: $vertical-padding-margin-based-off-vh, 
	$narrow: false, 
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip-low-vertical: $default-fluid-property-clip-low-vertical,
	$clip-high-vertical: $default-fluid-property-clip-high-vertical, 
	$clip: true, 
	$clip-at-start: true, 
	$clip-at-end: true
) {
	$divider: 1;

	@if $narrow {
		$divider: 2;
	}

	@if $vertical {
		@include fluid-property-vertical(padding-bottom, calc($small / $divider), calc($large / $divider), $clip-low-vertical, $clip-high-vertical, $clip, $clip-at-start, $clip-at-end);
	} @else {
		@include fluid-property(padding-bottom, calc($small / $divider), calc($large / $divider), $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
	}
}

@mixin padding-left($small: $collapse-padding-small,
	$large: $collapse-padding-large,
	$narrow: false,
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip: true,
	$clip-at-start: true,
	$clip-at-end: true
) {
	$divider: 1;

	@if $narrow {
		$divider: 2;
	}

	@include fluid-property(padding-left, calc($small / $divider), calc($large / $divider), $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
}

@mixin padding-right($small: $collapse-padding-small,
	$large: $collapse-padding-large,
	$narrow: false,
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip: true,
	$clip-at-start: true,
	$clip-at-end: true
) {
	$divider: 1;

	@if $narrow {
		$divider: 2;
	}

	@include fluid-property(padding-right, calc($small / $divider), calc($large / $divider), $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
}

@mixin padding-vertical(
	$small: $collapse-padding-small, 
	$large: $collapse-padding-large, 
	$vertical: $vertical-padding-margin-based-off-vh, 
	$narrow: false, 
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip-low-vertical: $default-fluid-property-clip-low-vertical,
	$clip-high-vertical: $default-fluid-property-clip-high-vertical, 
	$clip: true, 
	$clip-at-start: true, 
	$clip-at-end: true
) {
	@include padding-top($small, $large, $vertical, $narrow, $clip-low, $clip-high, $clip-low-vertical, $clip-high-vertical, $clip, $clip-at-start, $clip-at-end);
	@include padding-bottom($small, $large, $vertical, $narrow, $clip-low, $clip-high, $clip-low-vertical, $clip-high-vertical, $clip, $clip-at-start, $clip-at-end);
}

@mixin padding-horizontal($small: $collapse-padding-small,
	$large: $collapse-padding-large,
	$narrow: false,
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip: true,
	$clip-at-start: true,
	$clip-at-end: true
) {
	@include padding-left($small, $large, $narrow, $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
	@include padding-right($small, $large, $narrow, $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
}

@mixin padding(
	$small: $collapse-padding-small, 
	$large: $collapse-padding-large, 
	$vertical: $vertical-padding-margin-based-off-vh, 
	$narrow: false, 
	$clip-low: $default-fluid-property-clip-low,
	$clip-high: $default-fluid-property-clip-high,
	$clip-low-vertical: $default-fluid-property-clip-low-vertical,
	$clip-high-vertical: $default-fluid-property-clip-high-vertical, 
	$clip: true, 
	$clip-at-start: true, 
	$clip-at-end: true
) {
	@include padding-horizontal($small, $large, $narrow, $clip-low, $clip-high, $clip, $clip-at-start, $clip-at-end);
	@include padding-vertical($small, $large, $vertical, $narrow, $clip-low, $clip-high, $clip-low-vertical, $clip-high-vertical, $clip, $clip-at-start, $clip-at-end);
}
