.Panel {
    &__input {
        width: 80%;
        padding-left: 4px;
    }

    &__error-msg {
        padding-left: 4px;
    }

    &__button {
        background: transparent;
    }

    &__noborder {
        border: 0;
    }
}
