@import '../shared-styles/base/colours';

// Variable based overrides
$min-contrast-ratio: 2.5;

// Link style overrides
$link-color: $orange-2;
$link-hover-color: $orange-3;
$link-decoration: none;
$link-hover-decoration: underline;

// Container width overrides
$container-max-widths: (
  sm: 540px,
  md: 770px,
  lg: 990px,
  xl: 1140px,
  xxl: 1320px
) !default;

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Font Awesome library
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Orange buttons with chevron styles
@import './button';

// CSS overrides and independent styles
* {
  font-family: "FrederickSimms", Georgia !important;
}
.fa, .fas {
  font-family: "Font Awesome 5 Free" !important;
}
.fab {
  font-family: "Font Awesome 5 Brands" !important;
}
body {
  font-size: 15px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "FrederickSimmsBold", Georgia !important;
}
h3 {
  font-size: 1.25rem;
}
// Bootstrap overrides existing RAC styles that use default bs class names therefore overrides have been added here after Bootstrap has been imported above
.orange {
  color: $orange;
}
.white {
  color: $white !important;
}
.border-orange {
  border-color: $orange !important;
}
.bg-orange {
  background: $orange !important;
}
.badge {
  font-size: 1rem !important;
}
.card-link {
  font-weight: 600;
  color: $orange-4 !important;
}
.card-widget .card {
  border: none !important;
}
.tile.box > h4 {
  font-family: "FrederickSimmsBold", Georgia !important;
  font-weight: normal !important;
  padding: .5em;
}
.rac-checkbox:checked {
  background-color: #2a976f !important;
}
.MyRacForm
{
  &__button {
    font-size: 1rem;
    line-height: 1.4rem;
    border-radius: 4px !important;
  }
}
// My cover
.Standard-style {
  background-color: $blue-3 !important;
  border: 4px solid $blue !important;
  border-radius: 8px !important;
}
.Advanced-style {
  background-color: $orange-5 !important;
  border: 4px solid $orange !important;
  border-radius: 8px !important;
}
.Ultimate-style {
  background-color: $grey-16 !important;
  border: 4px solid $grey-14 !important;
  border-radius: 8px !important;
}
// Member Rewards
.reward-banner {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  margin-bottom: 1rem;
  padding: 5px;
  @media only screen and (max-width: 576px) {
    height: 100px;
    padding: 0 !important;
  }
}
// Alert
.alert-myrac {
  h3 {
    color: $orange !important;
  }
  padding: 0 !important;
  background-color: $grey-4;
}
// Modal
.fade:not(.show) {
  opacity: 1 !important;
}
.modal.myrac::-webkit-scrollbar {
  display: none; /* fix double scrollbar issue */
}
.modal-dialog {
  max-width: 576px !important;
}
.modal-backdrop.fade {
  background-color: $actual-black !important;
  opacity: 0.5 !important;
}
// MyRAC Login
.unknown-welcome > div > div > p {
  line-height: 1rem;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}
.gigya-style-modern.gigya-screen-dialog {
  margin: auto !important;
  font-family: "FrederickSimms", Georgia !important;
}
.gigya-screen .gigya-composite-control.gigya-composite-control-header {
  font-family: "FrederickSimms", Georgia !important;
}
.gigya-screen-dialog-main {
  &::-webkit-scrollbar {
    display: none; /* fix double scrollbar issue */
  }
  width: 1100px !important;
}
.gigya-screen.landscape {
  width: 100% !important;
}
.gigya-login-form {
  .gigya-composite-control-label, .gigya-label-text {
    font-weight: 600;
  }
  .gigya-layout-cell {
    align-items: center;
  }
  .with-divider {
    .gigya-layout-cell.with-social-login,
    .gigya-layout-cell.with-site-login {
      width: 50%;
    }
    margin: 0 !important;
  }
  .gigya-clear {
    display: none !important;
  }
  .gigya-input-submit,
  .gigya-register-here-link {
    font-size: 19px !important;
    background: $orange !important;
  }
  margin: 0;
  width: 100% !important;
  float: left !important;
}
// MyRAC Header
.nav-pills .nav-link {
  font-family: "FrederickSimmsBold", Georgia !important;
  height: 40px !important;
  padding: 7px 18px !important;
  font-size: 18px !important;
  color: #47474a;
  flex: none !important;
}
.nav-pills .nav-link.active {
  background-color: $orange;
  color: $white !important;
  border-color: $orange !important;
}
// MyRAC Content Layer
.container {
  max-width: 1190px !important;
}
.rac-content {
  @media only screen and (min-width: 768px) {
    margin-top: 4rem;
  }
  margin-top: 1rem;
}
// MyRAC Logout
.ascii-cross {
  background-color: transparent !important;
  border: none !important;
}
