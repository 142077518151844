$link-btn-chevron-height: 0.75em;
$link-btn-chevron-width: calc(15 / 19) * $link-btn-chevron-height;
$link-btn-caret-height: 0.3em;
$link-btn-caret-width: ($link-btn-caret-height * calc(12 / 5));
$link-btn-info-width: 1em;
$link-btn-icon-padding: 0.25em;

.LinkBtn {
	$c: &;
	@include button-reset;
	@include normal-weight;

	padding: 0;
	background: transparent no-repeat;
	color: $orange-2;
	text-decoration: underline;

	.has-hover &:hover {
		color: $orange-2;
	}

	.tabbing &:focus {
		box-shadow: 0 0 0 3px $form-field-focus;
	}

	// Modifiers

	&--bold {
		@include bold-weight;
	}

	&--no-underline {
		text-decoration: none;
	}

	&--chevron-right {
		padding-right: $link-btn-chevron-width + $link-btn-icon-padding;

		background: {
			image: url(../../assets/images/button/chevron-right-orange.svg);
			position-x: right;
			position-y: 70%;
			size: $link-btn-chevron-width $link-btn-chevron-height;
		}
	}

	&--caret-down {
		padding-right: $link-btn-caret-width + $link-btn-icon-padding;
		background: {
			image: url(../../assets/images/button/caret-down.svg);
			position-x: right;
			position-y: center;
			size: $link-btn-caret-width $link-btn-caret-height;
		}
	}

	&--caret-up {
		padding-right: $link-btn-caret-width + $link-btn-icon-padding;
		background: {
			image: url(../../assets/images/button/caret-down.svg);
			position-x: right;
			position-y: center;
			size: $link-btn-caret-width $link-btn-caret-height;
		}
	}

	&--info {
		padding-left: $link-btn-info-width + $link-btn-icon-padding;
		background: {
			image: url(../../assets/images/button/info-orange.svg);
			repeat: no-repeat;
			position-x: left;
			position-y: 70%;
			size: $link-btn-info-width $link-btn-info-width;
		}
	}

	&__link-label {
		text-decoration: underline;
	}

	&--grey {
		color: $grey-2;

		&#{$c} {
			&--caret-down:not(:hover) {
				background-image: url(../../assets/images/button/caret-down-grey.svg);
			}
		}
	}

	&--blue {
		color: $blue;

		.has-hover &:hover {
			color: $blue-2;
		}

		&#{$c} {
			&--caret-down {
				background-image: url(../../assets/images/button/caret-down-blue.svg);
			}

			&--caret-up {
				background-image: url(../../assets/images/button/caret-up-blue.svg);
			}

			&--info {
				background-image: url(../../assets/images/button/info-blue.svg);
			}
		}
	}

	&--white {
		color: $white;

		&#{$c} {
			&--info {
				background-image: url(../../assets/images/button/info-white.svg);
			}
		}

		.has-hover &:hover {
			color: $white;
		}
	}

	&:disabled {
		color: $grey-2;
		cursor: not-allowed;

		&:hover {
			color: $grey-2;
		}
	}
}
