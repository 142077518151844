@import 'shared-styles/core-variables-and-helpers';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  height: 70px;
  padding-left: 12px;
  box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 24%);

  @media only screen and (min-width: $tablet-large) {
    padding-left: 42px;
  }

  .mainContainer {
    display: flex;
    width: 1190px; // Used same width as the default MyRAC container
    justify-content: space-between;
    align-items: center;
  }
}
