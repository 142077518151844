$checkbox-checked-background-colour: $form-field-valid;

.Checkbox {
	&__input {
		display: none;
	}

	&__label {
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;

			cursor: pointer;

			margin-right: 0.25em;

			width: 1em;
			height: 1em;

			background: $white;

			border: 1px solid $black;

			border-radius: 3px;
		}
	}

	&__input:checked + &__label {
		&:before {
			background: $checkbox-checked-background-colour url(../../assets/images/button/tick-white.svg) center;
			background-size: 75%;
			background-repeat: no-repeat;
			border-color: $checkbox-checked-background-colour;
		}
	}
}
