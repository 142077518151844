@import '../shared-styles/core-variables-and-helpers';

.MyRacForm
{
    &__Modal {
        text-align: center;

        input {
            width: 300px;
            margin-bottom: 20px;
        }
    }

    &__center_button {
        text-align: center;
    }

    &__button {
        text-transform: capitalize;
        border-radius: 0;
    }

}

.Manual_entry {
    margin:20px 0 30px;
    text-align: center;

    button{
        font-size: 17px;
    }
}

.button_wrapper
{
    text-align: center;

    button{
        text-align: center;
        border-radius: 0;
    }
}

.add-vehicle-title,
.tile .add-vehicle-title {
    margin: 20px 0;
    text-align: center;
    font-size: 1.6rem;
    color: $grey;
    font-weight: 600;
}

.tile {
    .add-vehicle-title {
        background-color: transparent;
    }

    .success-title {
        background-color: transparent;
    }
}

.racIconFont.icon-searchIcon:before
{
    content: "\f002";
}
.search-icon {

    border: none;
    background-color: #0000;
    outline: 0;
    height: 55px;
    padding: 15px 0;

    i {
        font-size: 35px;
    }
}

.SuccessText {
    text-align: center;

    span {
        font-size: 72px;
    }
}

.success-title,
.tile .success-title
{
    color: $grey;
    padding-top: 20px;
    font-weight: 600;
    font-size: 1.5em;
}


.SuccessText .tex-center  {
    text-align: center;
}

.input-reg, .input-groups
{
    margin-bottom: 20px;
    padding: 0 10px;
}
.model_info
{
    align-items: center;
}
.cross-icon
{
    padding: 10px 15px;
    cursor: pointer;
    background-color: #0000;
    border: none;
    outline: 0;

    i {
        font-size: 28px;
    }
}

.delete
{
    margin-bottom: 40px;

    button {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px)
{

    .clear-input
    {
        padding: 0;
    }
    .cross-icon {
        padding: 10px 0;
    }
    .input-reg
    {
        padding:0;
    }
}
