.RenewalConfirmationPage {
    &__confirmation-header {
        color: #47474a;
        font-weight: normal;
        text-transform: none !important;
        margin-bottom: 1.5rem;
        padding-top: 2vw;
        font-size: 2rem;
        @media only screen and (max-width: 576px) {
            margin-top: 1rem;
        }        
        span {
            color: #f95108;
        }
    }

    &__policyreload-error {
        color: #f95108;
        font-weight: bolder;
    }

    &__left-column-wrapper,
    &__right-column-wrapper {
        font-size: 1rem;
        color: #47474a;
    }

    &__right-column-wrapper {
        div:first-child {
            background-color: #d9d9d9;
        }
    }

    &__confirmation-what-happens-next {
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1rem;
        line-height: 50px;
    }

    &__confirmation-icons {
        text-align: center;
        @media only screen and (max-width: 576px) {
            text-align: left;
        }
    }

    &__app-store-badge-wrapper {
        width: 100%;
        text-align: left;
        margin-top: 5px;
        margin-left: 45px;
        @media only screen and (max-width: 576px) {
            margin-left: -10px;
            text-align: center;
            a {
                padding-left: 0px;
            }
        }
    }    

    &__green {
        color: #1e9771;
        font-weight: 600;
    }

    &__back-button-container {
        margin-bottom: 2em;
        button {
            text-align: left;
            padding: 1rem;
            border-radius: 0.2em;
        }
    }

    &__confirmation-fca {
        padding: 5px;
        margin-bottom: 3rem;
        color: #7f7f7f;
    }
}