@import '../../../shared-styles/core-variables-and-helpers';

$maxBoxWidth: 1145px;

.Promotions {
  border-bottom: 2px solid rgba($grey-7, 0.2);
  padding: 0;


  &__box {
    padding: px-to-em(48px);
    max-width: $maxBoxWidth;
    margin: 0 auto;

    @include breakpoint(tablet, 'below') {
      padding: px-to-em(30px);
    }

    @include breakpoint(mobile-large, 'below') {
      padding: px-to-em(15px);
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @include breakpoint(tablet, 'below') {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__inner-wrapper {
    flex: 1 1 50%;
    padding: px-to-em(30px);

    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }

    @include breakpoint(tablet, 'below') {
      &--mobile {
        display: block;
      }

      &--desktop {
        display: none;
      }
    }
  }

  &__image {
    object-fit: cover;
    max-width: 100%;
  }

  &__heading {
    @include medium-weight;
    @include fluid-property('font-size', 20px, 30px);
    color: $grey-14;
    text-align: left;

    @include breakpoint(tablet, 'below') {
      text-align: center;
    }
  }

  &__body-text {
    @include light-weight;
    @include fluid-property('font-size', 18px, 24px);
    @include fluid-property('line-height', 24px, 28px);
    text-align: left;
    color: $grey-14;
    margin: 0 auto px-to-em(15px);

    @include breakpoint(tablet, 'below') {
      text-align: center;
    }
  }

  &__app-store-links {
    display: flex;
    align-items: flex-start;
    padding: 0.5em 0;

    @include breakpoint(tablet, 'below') {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__app-store-link {
    margin-right: 1.5em;
    object-fit: cover;

    @include breakpoint(tablet-landscape, 'below') {
      height: 4.5em;
    }

    @include breakpoint(tablet, 'below') {
      height: auto;
      margin: 0 0 1.5em 0;
      max-width: px-to-em(250px);
    }
  }
}
