.container {
  display: flex;
  padding: 0 .5rem;
  &:first-child {
    margin: none !important;
  }
  h4 {
    @media only screen and (max-width: 576px) {
      width: 85%; // Workaround for text overlapping modal close button on mobile
    }
  }

  .description {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: inherit;
      padding: 0 !important;
    }
    font-size: 1.15rem;
    padding: 16px;
    margin-top: 10px;
  }

  .title {
    color: #f95108;
    font-size: 1.6rem;
    font-weight: bold;
  }

  // Upgrades

  .upgradeTitle {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .upgradeDescription {
    margin-top: .5rem;
    margin-bottom: 0px;
  }

  .findOutMoreLink {
    cursor: pointer;
    text-decoration: underline;
    color: #386C87;
    font-weight: 600;
    line-height: 3rem;
  }

  .icon {
    margin-bottom: 2px;
    margin-right: 8px;
  }

  .addNowButton {
    &:hover {
      background: #ededed;
    }
    .price {
      s {
       color: #000; 
      }
      line-height: normal;
      margin-top: 1px;
      color: #f95108;
      float: right;
    }
    width: 60%;
    background: #fff;
    box-shadow: 0 2px 10px hsl(0deg 0% 46% / 30%);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
    cursor: pointer;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }    
  }

  .addNowSelected {
    &:hover {
      background: #61b59c;
    }
    span, s {
      color: #fff !important;
    }
    background-color: #1e9771;
    color: #fff;    
  }

  // Free Loyalty Rewards (FLR)

  .rewardTitle {
    font-size: 1.3rem;
    font-weight: 600;
    color: #47474a;
  }

  .rewardBody {
    color: #47474a;
  }

  .rewardInfo {
    margin-left: 63px;
  }

  .freeButton {
    display: flex;
    .price {
      color: #f95108;
    }
    background: #fff;
    border-radius: 3px;
    font-size: 1rem;
    text-align: left;
    padding: 1rem 1.2rem 0rem 1.2rem !important;
    cursor: pointer;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }    
  }

  .freeSelected {
    color: #1e9771;
  }

  .defaultText {
    color: #47474a;
  }

  .reward:last-of-type {
    margin-bottom: 0px;
  }

  .tickIcon {
    width: 28px;
    height: 28px;
  }

  .rewardBox {
    flex: 0 0 auto;
    width: calc(100% - 48px);
    margin-left: 20px;
  }
}
