@import '../shared-styles/core-variables-and-helpers';

.vehiclereminders {
    padding-left: 0;

    p {
        padding: 0;
        text-align: left;
    }
}

.vehicle-container {
    .card-header {
        font-weight: normal;
        background-color: $grey;
        color: $white;
    }
    .card {
        border-top: none;
        border-left: none;
        border-bottom: none;
        border-right: none;
        background-color: #e8f4f1;
    }
    .card.vehicle {
        @include breakpoint(tablet, up) {
            border-right: 1px solid #a5a5a5 !important;
        }
    }
    .card.mot-history, .bg-light {
        background-color: #f4f4f4 !important;
    }
    margin-bottom: 25px;
}

.row.display-flex.vehiclereminders-breakdowncover-box,
.row.display-flex.vehiclereminders-default-box{
    margin-bottom: 0;
    border-bottom: 0;
}

.tile.vehiclereminders-breakdowncover-box{
    border-radius: 4px 4px 0 0;
}

.vehiclereminders-default-box {
    background-color: $grey-14;
}
