@import '../../../shared-styles/core-variables-and-helpers';

.WhatIsCovered {
  &__toggle-button {
    border: none;
    background: transparent;
    padding: 0;
    color: $black;
  }
  &__chevron {
    width: 15px;
  }
  &__opened &__content {
    display: block;
  }
  &__opened &__chevron {
    transform: rotate(180deg);
  }
  &__closed &__content {
    display: none;
  }
  &__content p:last-child {
    margin-bottom: 0px
  }
}
