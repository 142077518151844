$loader-chevron-height: 1em;
$loader-dot-height: 0.9em;

$loader-chevron-spacing: 0.3em;

$loader-chevron-duration: 2s;
$loader-dot-duration: 1.6s;

$loader-dot-width: 0.3em;
$loader-chevron-width: $loader-chevron-height * 5;

$loader-chevron-animation-distance: $loader-chevron-width - ($loader-chevron-height * calc(15 / 19));

.Loader {
	position: relative;

	width: $loader-chevron-width * 1.5;
	height: $loader-chevron-height;

	opacity: 0;

	transition: opacity $transition-normal;

	&--dots {
		display: flex;
		align-items: center;
		width: 100%;
		height: auto;
	}

	&__chevron {
		display: inline;
		position: absolute;
		left: $loader-chevron-spacing;

		width: 100%;
		height: $loader-chevron-height;
		background-image: url(../../assets/images/button/chevron-right-white.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0;
	}

	&__dot {
		position: relative;
		width: $loader-dot-width;
		height: $loader-dot-height;
		opacity: 0;
		margin: 0 0.1em;

		background: {
			image: url(../../assets/images/button/dot-grey.svg);
			size: contain;
			repeat: no-repeat;
			position: center center;
		}
	}

	&--grey & {
		&__chevron {
			background-image: url(../../assets/images/button/chevron-right-grey.svg);
		}
	}

	&--orange & {
		&__chevron {
			background-image: url(../../assets/images/button/chevron-right-orange.svg);
		}
	}

	&--loading {
		opacity: 1;
	}

	&--loading & {
		&__chevron {
			animation: chevron-load $loader-chevron-duration infinite cubic-bezier(0.1, 0.5, 0.9, 0.5);

			&:nth-child(2) {
				left: 0;
				animation-delay: calc($loader-chevron-duration / 8);
			}

			&:nth-child(3) {
				left: -$loader-chevron-spacing;
				animation-delay: calc($loader-chevron-duration / 4);
			}
		}

		&__dot {
			animation: dot-load $loader-dot-duration infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);

			&:nth-child(2) {
				animation-delay: calc($loader-dot-duration / 8);
			}

			&:nth-child(3) {
				animation-delay: calc($loader-dot-duration / 4);
			}
		}
	}
}

@keyframes chevron-load {
	0%,
	20% {
		transform: translateX(calc($loader-chevron-animation-distance / -2));
		opacity: 0;
	}
	35% {
		opacity: 1;
	}
	65% {
		opacity: 1;
	}
	80%,
	100% {
		transform: translateX(calc($loader-chevron-animation-distance / 2));
		opacity: 0;
	}
}

@keyframes dot-load {
	0%,
	15% {
		transform: scale(0);
		opacity: 0;
	}
	45% {
		transform: scale(1);
		opacity: 1;
	}
	55% {
		transform: scale(1);
		opacity: 1;
	}
	85%,
	100% {
		transform: scale(0);
		opacity: 0;
	}
}
