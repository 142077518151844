@import '../../../../../shared-styles/core-variables-and-helpers';

.MembershipDocuments {
  $wrapper: &;
  height: 100%;
  min-width: 100%;
  padding: 0;
  position: relative;

  @include breakpoint(bootstrap-small, down) {
    padding-top: px-to-rem(35px);
  }

  & > Button {
    position: absolute;
    bottom: px-to-rem(17px);
  }

  &__content-container {
    padding: px-to-rem(17px);
  }

  &__button-container {
    position: absolute;
    bottom: 0;
    padding: px-to-rem(17px);
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
  }

  [class^='Button_Btn'] {
    margin-top: auto;
  }

  &__title {
    font-size: px-to-em(20px);
    margin: 0 0 px-to-rem(15px) 0;
    color: $white;

    @include breakpoint(bootstrap-small, down) {
      display: none;
      color: inherit;
    }
  }

  &__documents {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    @include breakpoint(1199px, down) {
      padding-bottom: 70px;
    }
  }

  &__date {
    font-size: px-to-em(12px);

    @include breakpoint(bootstrap-small, down) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__document {
    font-size: px-to-em(15px);
    color: $white;

    @include breakpoint(bootstrap-small, down) {
      color: inherit;
      padding-bottom: px-to-rem(25px);
    }
  }

  &__document-title-wrapper {
    display: flex;
    align-items: center;
  }

  &__document-title {
    margin-right: .5em;
    line-height: 1.1;
    padding-bottom: .25em;

    @include breakpoint(bootstrap-small, down) {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
    }
  }

  &__download-icon {
    font-size: 16px;
  }

  &__display-anchor {
    display: none;
  }

  &__download-button {
    border: 0 none;
    outline: 0;
    background-color: transparent;
    margin-left: auto;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
