@import '../../../../../shared-styles/core-variables-and-helpers';

.CoverDates {
    display: flex;
    justify-content: space-between;

    &__first-label,
    &__second-label,
    &__start-date,
    &__last-field-date{
        font-size: 13px;
    }

    &__start-date,
    &__last-field-date{
        font-weight: bold;
    }

    &__second-label {
        text-align: end;
    }

    &__last-field-date {
        text-align: right;
    }
}
