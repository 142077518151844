@import 'shared-styles/core-variables-and-helpers';

$checkCircleEmpty: '/images/icons/check-circle-empty.svg';
$checkCircleFill: '/images/icons/check-circle-green.svg';

.container {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 32px;
  background-color: #222222;
  color: white;

  @media only screen and (min-width: 993px) {
    padding: 40px 100px;
  }

  .paymentSwitch {
    .column {
      padding: 0;
    }
  }
}

.header {
  margin-bottom: 38px;
  h4 {
    margin-top: 4px;
    font: 700 1.2rem/1.7rem $base-font-family;
    text-align: center;

    @media only screen and (min-width: 993px) {
      font-size: 2.1rem;
      line-height: 2.5rem;
    }
  }
  p {
    font: 400 1rem/1.5rem $base-font-family;
    text-align: center;
    color: #808080;

    @media only screen and (min-width: 993px) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}

.note {
  padding: 0 0 16px;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.termsAndConditions {
  padding: 16px 0 32px;
  border-top: 1px solid #808080;
  font-size: 0.9rem;
  line-height: 1.3rem;
  a {
    text-decoration: underline;
    color: white;
  }
}

.paymentSwitch {
  position: relative;
  display: flex;
  margin-bottom: 16px;
}

.divider {
  position: absolute;
  top: 21%;
  left: calc(50% - 6px);
  font-size: 1rem;
  color: #808080;
}

.button {
  width: 100%;
  padding: 7px 6px;
  border-radius: 0 6px 6px 0;
  background: #DBDBDB;
  font: 700 1rem/1.5rem $base-font-family;
  text-align: center;
  transition: all 0.3s linear;
}

.icon {
  display: inline-block;
  width: 25px;
  height: 24px;
  margin-bottom: 24px;
  text-align: center;
  background: url($checkCircleEmpty) no-repeat;
  transition: all 0.3s linear;

  &.chosenIcon {
    background: url($checkCircleFill) no-repeat;
  }
}

.column {
  width: 50%;
	border: none;
	outline: inherit;
  background: none;
  color: #808080;
	cursor: pointer;

  &:first-child {
    .button {
      border-radius: 6px 0 0 6px;
    }
  }
  &.chosen {
    color: white;
    .button {
      background: #1E9771;
      color: white;
    }
  }
}


.price {
  margin-bottom: 8px;
  font: 700 2.5rem/2.5rem $base-font-family;
  text-align: center;
  transition: all 0.3s linear;

  @media only screen and (min-width: 993px) {
    font-size: 2.7rem;
    line-height: 2.8rem;
  }
}

.priceText {
  margin-bottom: 32px;
  font: 700 1rem/1.5rem $base-font-family;
  text-align: center;
  transition: all 0.3s linear;
}
