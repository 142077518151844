$radio-height: 1.1em;
$radio-dot-height: $radio-height * 0.65;

.Radio {
	&__input {
		display: none;
	}

	&__label {
		display: block;
		position: relative;
		padding-left: $radio-height + 0.5em;

		transition: font-size $transition-fast;
		line-height: 1em;

		cursor: pointer;

		&--svg-unchecked,
		&--svg-checked {
			display: none;
			width: $radio-height;
			height: $radio-height;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: transform $transition-fast;

			svg {
				width: $radio-height;
				height: $radio-height;
			}
		}

		&--svg-unchecked {
			display: block;

			svg {
				fill: $white !important;
			}
		}

		&:not(:last-child) {
			margin-bottom: 0.5em;
			transition: margin-bottom $transition-fast;
		}
	}

	&__input:checked + &__label .Radio__label--svg-checked {
		display: block;
	}

	&__label-text {
		display: inline-block;
		min-width: 60px;
		text-align: left;
	}
}
