@import '../../../shared-styles/core-variables-and-helpers';

.ExpiredPolicyWarning {
  &__container {
    background: #FEEEE6;
  }
  &__icon {
    margin-right: 16px;
  }
  &__title {
    font-size: 1.1rem;
    font-weight: bold;
    color: $orange;
    margin-bottom: 0;
    margin-top: 0.2rem;
  }
  &__description {
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__callUsBtn {
    padding-top: 8px;
    font: 700 1.5rem/1.7rem $base-font-family;
    a {
      text-decoration: underline;
    }
  }
}
