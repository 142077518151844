/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
|| Respond
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
@mixin respond($map){
    $string: "only screen";
    @if (map-get($map, min)){
        $min: map-get($map, min);
        $string: "#{$string} and (min-width: #{$min})";
    }
    @if (map-get($map, max)){
        $max: map-get($map, max);
        $string: "#{$string} and (max-width: #{$max})";
    }
    @media #{$string}{ @content; }
}
// how to use
/*
@include respond((min: 200px, max: 200px)) {
}
*/
