@import 'src/shared-styles/core-variables-and-helpers';

$transformAmount: 120%;

.section-wrapper {
    padding: 0;
}

@include breakpoint(tablet, 'below') {
    .siteNav > ul {
        -ms-transform: translate($transformAmount, 0);
        transform: translate($transformAmount, 0);
    }
}
