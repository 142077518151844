// Default button styling
.btn {
    &::after {
        content: "";
        position: relative;
        height: 1.25em;
        width: 1.25em;
        margin-left: 20px;
        transition: .2s;
        display: inline-block;
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        transition: transform .2s ease;
        background-image: url(../assets/images/button/chevron-right-white.svg);    
    }
    &:hover {
        border: 1px solid transparent;
        &::after {
            transform: translateX(3px);
        }
    }  
    background: $orange !important;
    font-weight: 600;
    text-align: left;
    font-size: 19px;
    padding: 1ex;
    margin: 0;
    position: relative;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: normal;
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
}
// Add button
.btn.add {
    &::after {
        height: 16px;
        background-image: url(../assets/images/button/plus-white.svg) !important;
    }
    &:hover {
        &::after {
            transform: none !important;
        }
    }    
}
.btn-primary {
    line-height: 16px;
}
.btn-secondary {
    &:hover {
        background-color: #585858 !important;
    }
    background: #484848 !important;
}
.btn-space {
    margin-right: 5px;
}
.btn-transparent {
    background-color: transparent;
}
.btn-secondary-myrac,
.btn-secondary-myrac:hover,
.btn-secondary-myrac:active,
.btn-secondary-myrac:visited {
    background-color: $white;
    color: $orange-2;
    border: 2px solid $orange-2;
}