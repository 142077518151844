$btn-chevron-icon-height: 1.35em;
$btn-chevron-icon-width: 1.2em;
@import 'src/shared-styles/core-variables-and-helpers';
@import 'src/shared-styles/components/Btn';

.Btn {
  &::after {
    font-size: inherit;  
  }
  font-size: 1.15rem;
  border-radius: 4px;
  letter-spacing: normal !important;

  &--ghost {
    background-color: transparent;
    border: 2px solid $orange;
    color: $orange;
  }

  &--grey {
    background-color: $grey-15;
  }

  &--dark-grey {
    background-color: $grey-14;
  }

  &--outline-black {
    border: 2px solid $black;
  }

  &--black {
    background-color: black;
  }

  &--color-black {
    color: black;
  }

  &--small-button {
    padding: .65em;
    max-width: 20rem;
    font-size: 16px;
  }

  &--large-button {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  &--margin-bottom {
    margin-bottom: 1.5em;
  }

  &--disabled {
    background-color: $grey-15;
    color: gray;
    cursor: not-allowed;
  }

  &--rounded {
    border-radius: 5px;
  }  

  &--chevron-right-narrow {
    @include button-icon(url(../../assets/images/button/chevron-right-white-narrow.svg), $btn-chevron-icon-width, $btn-chevron-icon-height);
  }
  &--chevron-right-narrow-black {
    @include button-icon(url(../../assets/images/button/chevron-right-black-narrow.svg), $btn-chevron-icon-width, $btn-chevron-icon-height);
  }
  &--chevron-right-narrow-orange {
    @include button-icon(
      url(../../assets/images/button/chevron-right-white-narrow-orange.svg),
      $btn-chevron-icon-width,
      $btn-chevron-icon-height
    );
  }
}
