
/* Date panle css*/

.selectContainer {
    width: 100%;
    margin: 50px auto auto;

    select {
        width: 30%;
        margin: 0;
        font-size: 100%;
        padding: 5px 10px 5px 10px;
        font-family: Segoe UI, Helvetica, sans-serif;
        border: 0;
        border: 1px solid #D0D0D0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
    }
}

.select{
    height: 55px;
    padding: 5px 10px 5px 10px;
    margin-top: 0;
}

.dobPanel{
    height: 40px;
    width: 98%;
    margin-left: 3px;
    padding-left: 10px;
    margin-top: 5px;
}

.selectContainer2 {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;

    div {
        padding-right: 5px;
        height: 100%;
        width: 33.33%;
        display: inline-block;
        top: 15px;
    }
}

.selectContainerMobileInline {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;

    select {
      border-color:black;
    }
    div {
        padding-right: 5px;
        height: 100%;
        display: inline-block;
        top: 15px;
    }
}

.selectYear {
  width:30%;
}

.selectMonth {
  width:40%;
}

.selectDay {
  width:29%;
}

#selectContainerDiv1 {
    float: left;
    top: 15px;
}

#selectContainerDiv2 {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

#selectContainerDiv3 {
    float: right;
    top: 15px;
}

/* Hide first value */
option[value="-1"]{
    display:none;
}


@media only screen
and (min-device-width : 320px)
and (max-device-width : 1024px)  {
    .selectContainer2 div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
    }

    .selectContainerMobileInline div {
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .margin-side .MyRacForm__button--wide {
        min-width: 70px;
        margin-right: 5px;
    }
}
