@import '../../../../shared-styles/core-variables-and-helpers';

.CollapsibleMobileTile {
    padding: 0.5em 0.8em 0.5em;
    border: 2px solid $orange;
    width: 100%;
    height: 100%;
    margin-bottom: 1.2em;
    outline: none;

    &__header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
    }

    &__heading-text {
        color: $orange;
        margin: 0;
        font-weight: bold;
        font-size: 1em;
    }

    &__chevron-left-icon {
        height: 1.3em;
    }

    &__chevron-down-icon {
        height: 0.5em;
    }

    &__underline {
        border-bottom: 1.2px solid rgba(#707070, 0.2);
        padding-bottom: 0.5em;
    }
}
