.content-break {
	position: relative;
	margin: 0;
	padding: 4em 0;
	border: 0;

	&:not(&--invisible):before {
		content: '';
		display: block;
		height: 1px;
		background: rgba($black, 0.2);
	}
}
