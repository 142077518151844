@import '../shared-styles/core-variables-and-helpers';
.changeAdditionalMembers {
    .Modal__visible {
        position: absolute !important;
        width: 50% !important;
    }
    &__modal-backdrop {
        display: block;
    }
    &__modal-small-header {
        text-align: center;
        padding: 12px 62px 0px 62px;
        @include breakpoint(mobile-large, down) {
            padding: 12px 15px 0px 15px;
        }
    }
    &__modal-header {
        padding-bottom: 0px !important;
    }
}

