$dialog-max-width: 600px;
$dialog-close-size: 35px;
$dialog-close-offset-small: 5px;
$dialog-close-offset-medium: 15px;
$dialog-close-offset-large: 25px;
$dialog-inner-padding: 5px;

.Dialog {
	z-index: 1000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;

	background: rgba(0, 0, 0, 0.8);

	transition: opacity $transition-fast;

	@media print {
		opacity: 1;
		position: initial;
	}

	&:not(&--display) {
		display: none;
	}

	&--overlay {
		opacity: 1;
	}

	&__dialog {
		@include padding;

		display: flex;
		flex-direction: column;

		position: absolute;
		top: 50%;
		left: 50%;

		width: 100%;
		max-width: $dialog-max-width;
		max-height: 100%;
		margin: 0;

		background: transparent;
		border: 0;

		transform: translate(-50%, 50%);
		opacity: 0;

		transition: opacity $transition-fast, transform $transition-fast;

		@media print {
			position: initial;
			transform: none;
			display: block;
			width: 100vw;
			max-width: 100vw;
			padding: 0;
		}
	}

	&--open &__dialog {
		transform: translate(-50%, -50%);
		opacity: 1;

		@media print {
			transform: none;
		}
	}

	&__outer {
		@include padding($collapse-padding-small - $dialog-inner-padding, $collapse-padding-large - $dialog-inner-padding);

		display: flex;
		flex-direction: column;
		position: relative;

		background: $white;
		border-radius: 5px;

		overflow-y: hidden;

		@media print {
			padding: 0;
		}

		.IE & {
			height: 100%;
			max-height: calc(100vh - #{($collapse-padding-large - $dialog-inner-padding) * 2});
		}
	}

	&__inner {
		@include minimal-scrollbar;

		display: flex;
		flex-direction: column;
		flex: 0 1 auto;

		padding: $dialog-inner-padding;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		@media print {
			padding: 0;
		}
	}

	&__close {
		@include button-reset;

		z-index: 2;
		position: absolute;
		top: $dialog-close-offset-small;
		right: $dialog-close-offset-small;
		width: $dialog-close-size;
		height: $dialog-close-size;

		@include breakpoint(tablet, up) {
			top: $dialog-close-offset-medium;
			right: $dialog-close-offset-medium;
		}

		@include breakpoint(desktop, up) {
			top: $dialog-close-offset-large;
			right: $dialog-close-offset-large;
		}

		border-radius: 50%;
		background: {
			color: $blue;
			image: url(../../assets/images/button/cross.svg);
			repeat: no-repeat;
			size: 50%;
			position: center center;
		}

		transition: background-color $transition-fast;

		.has-hover &:hover {
			background-color: $blue-4;
		}

		.tabbing &:focus {
			box-shadow: 0 0 0 3px $form-field-focus;
		}

		@media print {
			display: none;
		}
	}
}
