@import '../../../../../shared-styles/core-variables-and-helpers';

.MembershipStatus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 99%;
  width: 50%;

  @media only screen and (max-width: 992px) {
    width: 100%;
    margin-top: 20px;
  }

  &__status-and-dates-wrapper {
      height: auto;
      padding: 0 10px;
      margin-bottom: 15px;
  }
}
