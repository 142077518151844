@import '../../shared-styles/core-variables-and-helpers';

.ProductUpgradeJourney {

  &__button-container {
    display: flex;
    @include breakpoint(bootstrap-small, 'down');
    @include breakpoint(tablet-large, 'below');
    @include breakpoint(tablet, 'below') {
      flex-direction: column;
      width: 100%;
    }
  }

  &__product-title {
    h3 {
      color: $grey-14;
      @include fluid-property('font-size', 30px, 35px);
      font-weight: normal;

      @include breakpoint(bootstrap-small, 'down') {
        line-height: 1.2;
      }

      strong{
        @include breakpoint(bootstrap-small, 'down') {
          white-space: pre;
        }
      }
    }
  }

  &__product-description, &__info-link {
    font-size: 18px;
    margin: 1.3em 0 1.3em;
  }

  &__info-link {
    color: #386c87;
    cursor: pointer;
    text-decoration: underline;
  }

  &__button {
    @include medium-weight;
    font-size: px-to-rem(18px);
    line-height: 1.2;
    padding: 0.50em;
    border-radius: 0.2em;
    text-align: center;
    border-style: solid;
    @include breakpoint(bootstrap-small, 'down') {
      width: 100% !important;
    }
    @include breakpoint(bootstrap-small, 'down');
    @include breakpoint(tablet-large, 'below');
    @include breakpoint(tablet, 'below') {
      margin-left: 0;
      margin-top: 0.6em;
    }
  }

  &__orange {
    background: $orange;
    color: $white;
    border-color: $orange;
    margin-right: 0.5em;
  }

  &__darkgrey {
    background: $grey-14;
    border-color: $grey-14;
    color: $white;
  }

  &__grey {
    background: $grey-15;
    border-color: $grey-15;
    color: $actual-black;
    @include breakpoint(desktop, 'up');
    @include breakpoint(tablet-landscape, 'up');
    @include breakpoint(tablet-large, 'up');
    @include breakpoint(tablet, 'up') {
      margin-left: 0.5em;
    }
  }

  &__orange,
  &__darkgrey
  &__grey {
    @include breakpoint(bootstrap-small, 'down') {
      width: 100%;
    }
  }

  &__cta {
    width: 290px;
  }

  &__find {
    @include breakpoint(desktop, 'up');
    @include breakpoint(tablet-landscape, 'up');
    @include breakpoint(tablet-large, 'up');
    @include breakpoint(tablet, 'up') {
      display: block;
      width: 250px;
    }
  }

  &__save {
    @include breakpoint(desktop, 'up');
    @include breakpoint(tablet-landscape, 'up');
    @include breakpoint(tablet-large, 'up');
    @include breakpoint(tablet, 'up') {
      display: block;
      width: 250px;
      margin-top: -3.3rem;
    }
  }

  &__terms-condition {
    margin-top: 2em;

    p {
      margin-bottom: 0;
      @include fluid-property('font-size', 13.5px, 14px);
    }
  }

  &__loader {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    text-align: center;
  }

  &__icon {
    height: 3em;
    width: 3em;
  }

  &__grey-bg {
    background: #ededed;
  }
}
