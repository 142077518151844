.container {
  position: sticky;
  top: 15vh;
  padding: 30px;
  width: 37rem;
  height: fit-content;
  border: 3px solid #f95108;
  border-radius: 10px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  button {
    border-radius: 5px;
  }
  @media only screen and (max-width: 992px) {
    width: 90vw;
    position: relative;
    top: 0;
    margin: 1.5rem 0rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 2rem;
      margin: 0;
    }
    i::before {
      width: 100px;
    }
    sub {
      position: static;
      font-weight: 500;
    }
  }

  .headerWithBorder {
    align-items: baseline;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(237, 237, 237);
  }

  .discountApplied {
    display: inline-flex;
    color: #f95108;
    top: 10px;
    font-size: 1.2rem;
  }

  .instalmentCountInfo {
    display: inline-flex;
    font-size: 1.2rem;
  }  

  .paymentMethod {
    color: inherit;
  }

  .extrasList {
    i {
      margin-top: 0.2rem;
    }
    line-height: 1.6;
    font-size: 1.1rem;
  }

  .findOutMoreLink {
    cursor: pointer;
    text-decoration: underline;
    color: #386C87;
    font-weight: 600;
    line-height: 3rem;
  }

  .icon {
    margin-bottom: 2px;
    margin-right: 8px;
  }
  
  .termsAndConditions {
    padding-bottom: 5px;
    margin-bottom: 15px;
    color: grey;
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
}

.monthlyHeadlinePriceWas {
  position: relative;
  display: inline-block;
  color: grey;
  font-size: 0.75em;
  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 50%;
    left: 0;
    border: 1px solid grey;
  }
}
