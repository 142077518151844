@import '../../../../../shared-styles/core-variables-and-helpers';

.countdown {
  position: relative;
  width: 100%;
  height: 100%;

  &__image-style {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
  }

  &__container {
    position: absolute;
    width: 100%;
    top: 52%;

    @include respond((min: 378px, max: 414px)) {
      top: 54%;
    }

    @include respond((min: 415px, max: 502px)) {
      top: 55%;
    }

    @include respond((min: 503px, max: 991px)) {
      top: 56.5%;
    }
  }

  &__row {
    padding: 10px 0 20px 0;
    text-align: center;
    column-count: 4;
    column-gap: 2px;
    color: $white;
  }

  &__number {
    line-height: 1.2;
    @include fluid-property('font-size', 50px, 80px);

    @include breakpoint(bootstrap-small, down) {
      line-height: normal;
    }
  }

  &__time {
    @include fluid-property('font-size', 16px, 20px);
  }

  &__button {
    button {
      position: relative;
      margin-top: 5%;
      
      @include respond((max: 991px)) {
        margin-top: 8%;
      }
    }
  }
}

.countdown-image-tile {
  width: 100%;
  height: 100%;
}
