.Switch {
	position: relative;

	background: rgba(0, 0, 0, 0.1);

	font-size: 1.2em;

	width: 2em;
	height: 1em;

	border-radius: 0.5em;

	&__input {
		position: absolute;
		visibility: hidden;
	}

	&__label {
		z-index: 2;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		opacity: 0;

		cursor: pointer;
	}

	&__input:checked + &__label {
		display: none;
	}

	&__indicator {
		position: absolute;

		width: 1em;
		height: 1em;
		border-radius: 1em;
		box-shadow: 0 3px 5px 0 rgba($grey, 0.3);
		background: $white;

		transition: background-color $transition-fast, transform $transition-fast;

		cursor: pointer;

		pointer-events: none;
	}

	&__input:nth-child(1):checked ~ &__indicator {
		transform: translateX(1em);

		background: $grey;
		box-shadow: 0 3px 5px 0 rgba($grey, 0.3);
	}
}
