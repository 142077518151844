// This is needed for the gigya screensets on lcp landing. This styling is terrible and I have copied from main site - apologies.
// This can be deleted once we rebuild the screensets.

.quick-modal-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($black,.4);
	// Gigya screenset z-index fluctuates between z-indexes - this is the only way to be sure...it seems. wtf? :/ - jk
	z-index: 2000000039;
}

.quick-modal {
	display: none;
	position: fixed;
	top: 4%;
	left: 0;
	width: 96%;
	margin-left: 2%;
	background-color: $white;
	border-radius: 3px;
	box-shadow: 0 19px 38px rgba($black, .3), 0 15px 12px rgba($black, .22);
	text-align: center;
	padding: 18px;
	// Gigya screenset z-index fluctuates between z-indexes - this is the only way to be sure...it seems. wtf? :/ - jk
	z-index: 2000000040;
	max-height: 84%;
	overflow: auto;

	h2 {
		font-size: 1.74em;
	}



	.header {
		position: relative;
		width: 100%;
	}

	.control {
		font-size: 25px;
		font-weight: 600;
		position: absolute;
		top: -12px;
		right: 0;

		a {
			text-decoration: none;
		}
	}

	.full-width {
		width: 100%;
		height: auto;
		margin: 8px 0 22px 0;
	}
}

@include breakpoint(tablet, up) {
	.quick-modal {
		top: 8%;
		width: 44%;
		margin-left: 28%;
	}
}