@import '../../shared-styles/core-variables-and-helpers';

$bismarkBlue: #436A88;

.popupModal {
    display: block;

    &__modal-container {
        background: rgba(71, 71, 74, 0.7);
        opacity: 1;
        transition: opacity .15s linear;
    }

    &__banner-container {
      background: transparent !important;
    }

  &--modal-container {
    background: rgba(71, 71, 74, 0.7);
    opacity: 1;
    transition: opacity .15s linear;
  }

    &--info-modal-container {
        background:inherit;
    }

    &__info-modal-container {
      background:inherit;
    }

    &__modal-content-wrapper {
        text-align: left;
    }

    &__package-dialog-close-button {
      color: $grey-7 !important; // Needed to overwrite rac.min.css file
      font-weight: lighter !important;
        &:hover {
            color: $orange-2 !important;
        }
      @include breakpoint(mobile-large, down) {
          top: 10px !important;
      }
    }

    &__dialog-box {
        display: block;
        height: auto;
        width: 600px;
        transition: transform .3s ease-out;

        @include breakpoint(mobile-large, down) {
            width: auto;
            margin: 20px;
        }

      &--popup-title {
        margin: 22px 0 0 !important;
        text-align: inherit;
        font-size: 23.8px !important;
        font-weight: bold;
        color: $grey-14 !important; // needed to overwrite the rac.min.css file

        @include breakpoint(mobile-large, 'down') {
          padding: 0 15px !important;
        }
      }

      &--popup-title,
      &--dialog-body {
        padding: 0 43px !important;
        @include breakpoint(mobile-large, 'down') {
          padding: 0 15px !important;
        }
      }
    }

    &__small-dialog-box {
      display: block;
      height: auto;
      width: 460px;
      transition: transform .3s ease-out;
      text-align: center !important;

      @include breakpoint(mobile-large, down) {
        width: auto;
        margin: 20px;
      }

      &--popup-title {
        margin: 22px 0 0 0 !important;
        font-size: 23.8px !important;
        font-weight: bold;
        color: $grey-14 !important; // needed to overwrite the rac.min.css file

        @include breakpoint(mobile-large, 'down') {
          padding: 0 15px !important;
        }
      }

      &--popup-title,
      &--dialog-body {
        padding: 0 76px !important;
        @include breakpoint(mobile-large, 'down') {
          padding: 0 15px !important;
        }
      }

      &--button-wrapper {
        margin: 0 61px;
      }
    }

    &__inner-close-button {
        position: absolute;
        text-align: right;
        top: 20px;
        font-size: 55px !important;
        color: $grey-7;
        line-height: 0.6em !important;
        right: 20px;
        outline: 0;
    }
    
    &__info-dialog-close-button {
        font-size: 40px !important;
    }

    &__outer-close-wrapper {
        height: 48px;
        width: 48px;
        position: absolute;
        z-index: 30;
        text-align: right;
        top: -1.2em;
        right: -1em;
    }

    &__outer-button {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        width: 100%;
        height: 100%;
        background-color: $bismarkBlue;
        color: $white;
        border-radius: 50%;
        border: 3px white solid;
    }

    &__outer-button-text {
        display: inline-block;
        font-size: 56px;
        font-weight: 200;
        line-height: 0.43 !important;
        padding-bottom: 1.5px;
        margin: 0 0 10px !important;
    }

    &--dialog-top-quarter {
        top: 30%;
        transform: translate(0, -30%);
    }

    &--dialog-middle {
        top: 50%;
        transform: translate(0, -50%);
    }

    &__header-container {
        padding: 20px 0 9px 0;
        border-bottom: none;
        position: relative;
    }

    &__info-dialog-header-container {
        padding-bottom: 20px;

        h4 {
            text-align: center;
        }
    }

    &__header-row {
        margin: 0;
    }

    &__info-dialog-body {
        font-size: 18px;
        text-align: center;
        padding: 12px 62px;

        @include breakpoint(mobile-large, down) {
            padding: 12px 15px;
        }

        li {
            font-size: 15px;
            text-align: left;
        }

        h5 {
           color: $grey !important;
           font-weight: normal !important;
           font-size: 21px !important;

            @include breakpoint(mobile-large, down) {
                text-align: left;
            }
        }
    }

    &__popup-body {
        margin: 0;
        z-index: 500;
        overflow-y: auto;
        max-height: 350px;

        h4 {
            margin: 12px 0;
            padding: 0;
            text-align: inherit;
            font-size: 24px;
            font-weight: bold;
            color: $grey-14;
            background-color: white;
        }

        p {
            font-weight: normal;
            color: $grey-14;
            margin-top: 0;
            margin-bottom: 20px !important;
        }

        h5 {
            font-size: 16px;
            color: $orange;
            font-weight: bold;
            margin-bottom: 0;
        }

        ul {
          padding-left: 40px;
        }
    }

    &__popup-no-body {
      margin: 0;
      overflow: hidden;
    }

    &__modal-footer {
        border-top: none;
        text-align: center;
        padding: 6px 15px 22px 15px;
    }

    &__button-wrapper {
        text-align: center;
        margin: 0 17%;
        width: auto;
        display: block;
    }

    &__cta-bright-orange-button {
        background-color: $orange;
        border-radius: 4px !important;
        padding: 14px 0;
        width: 100%;
        font-size: 18px;
    }

    &__button-secondary-grey {
      background-color: $grey-7;
      margin-bottom: -11px;
    }

    &__button-primary-orange {
        background-color: $orange;
    }
}
