@import '../shared-styles/core-variables-and-helpers';

.message-dialog-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
}

.success-text {
    .header {
        text-align: center;
    }

    .header {
        .title {
            color: #1E9771;
            background-color: $white;
            padding-top: 20px;
            font-weight: 600;
            font-size: 1.5em;
            text-align: center
        }

        .success-title  {
            color: $grey;
        }

        .myrac-icon-checked {
            font-size: 72px;
        }

        p {
            text-align: center
        }
    }
}

.failure-text {
    .header {
        text-align: center;

        .title {
            color: #FF0000;
            background-color: $white;
            padding-top: 20px;
            font-weight: 600;
            font-size: 1.5em;
            text-align: center
        }

        .myrac-icon-cancel {
            font-size: 72px;
        }

        p {
            text-align: center
        }
    }
}
