@import 'shared-styles/core-variables-and-helpers';

.container {
  .title {
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }
  .contentContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    margin: 40px 0px;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }

    .formContainer {
      flex: 1;
      padding: 20px;
      background-color: $grey-f8;
      border-radius: 6px;

      @media only screen and (max-width: 800px) {
        width: 100%;
      }
    }

    .passwordInfoContainer {
      flex: 1;
      border: 4px solid #1C6EA4;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: fit-content;

      @media only screen and (max-width: 800px) {
        flex: 3;
      }
      .description {
        font-weight: 700;
      }
      .list {
        padding-left: 1.2rem;
      }
      .additionalDescription {
        margin: 0px;
      }
    }
  }
}
