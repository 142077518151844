@import '../shared-styles/core-variables-and-helpers';

.dateContainer
{
    position: relative;
    overflow: hidden;
    margin: auto;

    select
    {
        width: 100%;
        margin: 0;
        font-size: 100%;
        padding: 5px 10px 5px 10px;
        font-family: Segoe UI, Helvetica, sans-serif;
        border: 0;
        border: 1px solid #D0D0D0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
    }

    div {
        padding-right: 5px;
        height: 100%;
        width: 40%;
        display: inline-block;
        top: 15px;
    }
}

#dateContainerDiv3 {
    float: right;
    top: 15px;
}
#dateContainerDiv2 {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
