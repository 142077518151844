@import 'shared-styles/core-variables-and-helpers';

.containerBackground {
  background-color: $grey-11;

  @media only screen and (min-width: $tablet) {
    background-color: white;
  }
}

.containerConfirmation {
  background-color: $orange;

  @media only screen and (min-width: $tablet) {
    background-color: white;
  }
}

.content {
  width: 100%;
  max-width: 716px;
  margin: 0 auto;
  color: $grey-14;

  @media only screen and (min-width: $tablet) {
    margin: 0 auto 42px;
    background-color: $grey-11;
  }
}