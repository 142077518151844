@import 'shared-styles/core-variables-and-helpers';

.pageTitle {
  margin-bottom: 0.5rem;

  h3 {
    color: #f95108;
    font-size: 2rem;
    margin: 0;
  }
}
