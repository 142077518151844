.VideoContainer {
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: percentage(calc(9 / 16));
	}

	background: rgba(0, 0, 0, 0.1);

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
