.productTitle, .basketPrice {
  h3 {
    font-size: 2.2rem;
    color: #47474A;
    strong {
      font-family: "FrederickSimmsBold", Georgia !important;
      color: #f95108;
    }
  }
}

.photoFrame {
 border-radius: 5%;
}

.formTitle {
  color: #47474A;
}

.infoLink {
  color: #386c87;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}


.icon {
  font-size: 20px;
  vertical-align: middle;
}