@import '../shared-styles/core-variables-and-helpers';

.InfoBanner {
    min-height: 40px;
    width: 100%;
    position: absolute;
    top: -46px;
    display: flex;
    justify-content: center;
    z-index: 1;

    @include breakpoint(tablet, below) {
        top: -13px; // For all other browsers than IE

        @include ie-11-only() {
            top: 3px;
        }
    }

    &__banner-body {
        background-color: $blue;
        max-width: 75%;
        border-radius: 0.25em;
        padding: 0.8em 1em;
        display: -ms-flexbox;

        @include breakpoint(tablet, below) {
            max-width: 100%;
        }

        @include respond((min: 768px, max: 991px)) {
            max-width: 70%;
        }
    }

    &__banner-message {
        @include fluid-property('font-size', 14px, 16px);
        margin: 0 0.5em 0 0.8em;
        padding-right: 0.9em;
        align-self: center;

        @include breakpoint(tablet, below) {
            @include ie-11-only() {
                margin-left: 1.25em;
            }
        }
    }

    &__banner-icon-container {
        height: 1.5em;
        width: 1.5em;
    }

    &__banner-info-icon {
       height: 100%;
    }

    &__banner-message-container,
    &__banner-inner-body {
        display: flex;
        align-items: center;
    }

    &__banner-message-container {
        border-right: 1px solid rgba($white, 0.35);
    }

    &__banner-message,
    &__banner-close-button-container {
        color: $white;
        font-weight: bold;
    }

    &__banner-close-button-container {
        @include fluid-property('font-size', 16px, 18px);
        padding: 0 0.5em 0 1em
    }

    &__banner-close-button {
        margin-bottom: 0;
    }

    &__banner-button {
        background-color: transparent;
        outline: none;
        border: none;
    }
}
