@import 'shared-styles/core-variables-and-helpers';

.confirmationScreenWrapper {
  min-height: calc(100vh - 70px);
  padding-bottom: 50px;
  border-radius: 6px;
  background-color: $orange;

  @media only screen and (min-width: $tablet) {
    min-height: auto;
    padding-bottom: 33px;
    margin: 64px auto 0;
  }

  &.isAppView {
    min-height: 100vh;

    @media only screen and (min-width: $tablet) {
      min-height: auto;
    }
  }
}

.info {
  padding: 20px;
  margin: 40px 26px 140px;
  border-radius: 6px;
  background-color: white;

  h2 {
    position: relative;
    max-width: 550px;
    padding-left: 50px;
    margin: 0 auto 10px;
    font: 700 1.5rem / 1.6rem $base-font-family;
    color: $green;

    @media only screen and (min-width: $tablet) {
      margin: 0 auto 6px;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      width: 37px;
      height: 37px;
      margin-right: 20px;
      background-image: url('/assets/images/icons/check-green-tick.svg');
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: 0 0;
    }

    b {
      display: block;
      margin-right: 8px;
      font: 700 2.15rem / 2.5rem $base-font-bold-variant;

      @media only screen and (min-width: $tablet) {
        display: inline;
      }
    }
  }

  p {
    max-width: 550px;
    padding-left: 50px;
    margin: 0 auto;
    font: 400 0.9rem / 1.25rem $base-font-family;
  }
}

.btn {
  display: block;
  width: calc(100% - 52px);
  min-width: 220px;
  padding: 18px;
  margin: 0 auto;
  border-radius: 4px;
  border: 0;
  outline: 0;
  font: 700 1.5rem / 1.75rem $base-font-family;
  text-align: center;
  background-color: white;
  color: $orange;
}
