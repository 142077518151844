* {
	box-sizing: border-box;
}

body {
	min-width: $min-width;
}

fieldset {
	@include fieldset-reset;
}

input,
textarea,
select {
	&:-webkit-autofill {
		box-shadow: 0 0 0px 1000px $white inset;
	}
}

a {
	color: $orange;

	.has-hover &:hover {
		color: $orange-2;
	}

	.tabbing &:focus {
		box-shadow: 0 0 0 3px $form-field-focus;
	}
}
