@import '../../../../../shared-styles/core-variables-and-helpers';

.CoverBadge {
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0.5em;
    display: flex;
    height: 9.8em;
    position: relative;
    overflow: hidden;

    &__badge {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        object-fit: none;

        @include breakpoint(bootstrap-small, down) {
            width: auto;
        }
    }
}
