@import 'shared-styles/core-variables-and-helpers';

.header {
  display: block;
  margin: 0 auto;
  font-size: 1.1rem;

  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }

  .title {
    font-size: 1.8rem;

    @media only screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    max-width: 685px;
  }

  .clear {
    clear: both;
  }
}

.paymentHeader {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 6px;

  h3 {
    margin: 0;
  }
  p {
    margin: 0;
    font: 700 1.4rem / 1.7rem $base-font-family;
    color: black;
  }
  b {
    display: block;
    font: 700 0.9rem / 1.3rem $base-font-family;
    color: $orange;
  }
  span {
    display: block;
    font: 400 1rem / 1.5rem $base-font-family;
    color: $orange;
  }
  h3 + div {
    font: 700 1.4rem / 1.7rem $base-font-family;
    text-align: right;
    color: $orange;

    button {
      display: block;
      width: 100%;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 1rem;
      text-decoration: underline;
      text-align: right;
      background: none;
      color: $grey-18;
    }
  }

  &.closed {
    box-shadow: 0px 1px 4px 0px #00000026;

    b {
      font-weight: 400;
      color: $grey-18;
    }
    span {
      color: $grey-18;
    }
    h3 + div {
      color: black;
    }
  }
}

.compactVersionContainer {
  padding: 16px;
  margin: 20px auto 24px;
  border-radius: 6px;
  background-color: $grey-16;
  color: $grey-14;

  @media only screen and (min-width: 768px) {
    background-color: $grey-f8;
  }

  h3 {
    font-size: 1.4rem;

    @media only screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
  img {
    width: 100px;
    margin-bottom: 42px;
  }
  .info {
    margin: 0 auto 24px;
    font-size: 1rem;

    p {
      font-weight: bold;
    }

    span {
      color: #f95108;
      line-height: 1.5rem;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  button {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}