@import '../../../../shared-styles/core-variables-and-helpers';

.ManagedCta {
    $self: &;
    position: absolute;
    font-size: px-to-em(16px);
    font-weight: bold;
    border: none;
    outline: none;
    min-width: 143px;
    padding: 0.6em 2.1em 0.6em 0.6em;
    line-height: 1.3em;
    text-align: left;

    &__loader {
        position: absolute;
    }

    &__bottom-left {
        bottom: 1rem;
        left: 1rem;
        @include breakpoint(mobile-large, down) {
            bottom: 6%;
            left: 9%;
        }
    }

    &__bottom-center {
        transform: translate(-50%,-50%);
        bottom: 6.5%;
        left: 50%;
    }

    &__center-center {
        transform: translate(-50%,-50%);
        bottom: 40%;
        left: 50%;
     }

    &#{$self}__bottom-center {
        @include breakpoint(bootstrap-small, down) {
            font-size: px-to-em(22px);
        }
    }

    &#{$self}__bottom-left{
        @include breakpoint(bootstrap-small, down) {
            line-height: 1.5;
            font-size: px-to-em(18px);
        }
    }

    &:after {
        content: '';
        position: absolute;
        background-image: url("../../../../assets/images/button/chevron-right-white.svg");
        right: 0.75em;
        width: 1.2em;
        height: 1.3em;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        top: 50%;
        transform: translate(0, -50%);
    }
}
