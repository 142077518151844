.siteLogo {
  height: 30px;
  margin: 15px 0;
  padding: 0;
  text-indent: -999px;
  font-size: 12px;
}

.siteLogoTop {
  height: 45px;
  margin: 20px 0;
  padding: 0;
  text-indent: -999px;
  font-size: 12px;

  @media only screen and (max-width: 767px) {
    height: 30px;
    margin: 15px 0;
  }
}
