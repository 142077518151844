.Modal__visible {
    opacity: 1;
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 3px 9px #333;
    background-clip: padding-box;
    outline: 0;
}

.Modal__block {
    display: block;
}

.Modal__overflow {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
}

.Modal__title-white {
    background: #fff !important;
}

.modal-content {
    border: none !important;
}
