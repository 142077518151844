@import '../../../shared-styles/core-variables-and-helpers';

.container {
  .title {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: $title-grey;
  }
  .vehiclesReminderContainer {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;

    @media only screen and (max-width: 1060px) {
      flex-direction: column-reverse;
    }

    .vehiclesContainer {
      flex: 2;
      .noVehiclesText {
        font-size: 1.2em;
      }
    }
    .remindersContainer {
      flex: 1;
    }
  }
}

.salutation {
  margin-bottom: 22px;
  font: 700 44px/52px $base-font-family;
  color: $grey-14;
}

.addVehicleBtnContainer {
  width: 45%;

  @media only screen and (max-width: 767px) {
    width: 80%;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  button {
    font-size: 1.5rem;
    padding: 0.9rem;
  }
}
