@mixin container($max-width: 1110px, $padding-small: 10px, $padding-large: $collapse-padding-large, $clip-at-start: true, $clip-at-end: true) {
	@include padding-horizontal($padding-small, $padding-large, $clip-at-start: $clip-at-start, $clip-at-end: $clip-at-end);

	margin: 0 auto;
	max-width: $max-width + ($padding-large * 2);
}

.Container {
	@include container;
}
