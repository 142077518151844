.fieldContainer {
  .passwordLabel {
    font-weight: 700;
    margin-bottom: 10px;
    color: #5d5d5f;
  }
  .buttonContainer {
    margin-bottom: 20px;
    position: relative;
    input {
      height: 50px;
    }
    .buttonShow {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      outline: inherit;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      opacity: 0.7;
    }
  }
}

.button {

}
