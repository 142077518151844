@import '../../../shared-styles/core-variables-and-helpers';
.container {
  position: sticky;
  top: 15vh;

  .borderContainer {
    border: 2px solid $orange-8;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;

    @media only screen and (max-width: 1060px) {
      position: static;
    }

    button {
      font-size: 1.5rem;
      padding: 0.9rem;
    }

    .title {
      font-size: 1.5rem;
      line-height: 2.1rem;
      margin: 0;
      font-weight: 700;
      color: $grey-14;
    }

    .remindersContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 5px;
      background-color: $grey-16;
      padding: 20px;

      .noRemindersSet {
        margin: 0;
      }
    }
  }

  .smallPrint {
    color: $grey-14;
    margin: 20px 0px;
    text-align: justify;
  }
}
