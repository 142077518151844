@import '../../../shared-styles/core-variables-and-helpers';

$desktopBannerHeight: 610px;
$mobileBannerHeight: 820px;
$desktopBannerHeightNew: 730px;
$mobileBannerHeightNew: 70rem;

$maxBoxWidth: 1145px;

.LandingBanner {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: relative;
  padding: 0;

  @include breakpoint(tablet, below) {
    margin-top: 0px;
  }
 
  &__background-image {
    max-width: 100%;
    max-height: $desktopBannerHeight;

    &--newVersion {
      height: $desktopBannerHeightNew;
      max-height: $desktopBannerHeightNew;
    }

    &--mobile {
      display: none;
      object-fit: fill;
    }

    &--desktop {
      display: block;
      object-fit: cover;
    }   

    @include breakpoint(tablet, below) {
      &--mobile {
        display: block;
      }

      &--desktop {
        display: none;
      }
    }

    @include breakpoint(tablet-landscape, below) {
      height: $desktopBannerHeight;
      &--newVersion {
        height: $desktopBannerHeightNew;
      }
      max-height: 100%;
    }

    @include breakpoint(tablet, below) {
      height: $mobileBannerHeight;
      &--newVersion {
        height: $mobileBannerHeightNew;
      }
      max-height: 100%;
    }

    @include ie-11-only() {
      display: none;
    }
  }

  @include ie-11-only() {
    background-size: cover;
    background-position: center center;
    height: $desktopBannerHeight;
    max-width: 100%;
  }

  &__box {
    position: absolute;
    left: 50%;
    top: 365px;
    width: 100%;
    max-width: $maxBoxWidth;
    padding: px-to-em(40px);
    transform: translate(-50%, -50%);
    background-color: rgba($white, 0.93);
    box-shadow: 0 3px 6px $black;
    z-index: 1;
    -webkit-font-smoothing: antialiased;

    @include breakpoint(tablet-landscape, below) {
      top: 35rem;
      padding: px-to-em(20px);
      width: 94%;
    }

    @include breakpoint(mobile-large, below) {
      top: 35rem;
      padding: px-to-em(5px) px-to-em(5px);
    }
  }

  &__heading {
    @include medium-weight;

    &--main {
      text-align: center;
      width: 98%;
      margin-bottom: 1.5rem;
      
      @include breakpoint(tablet, below) {
        margin-top: 1.5rem;
      }
    }

    &--sub {
      @include fluid-property('font-size', 20px, 30px);
      color: $grey-14;
      text-align: center;
      margin: 0;
      padding-bottom: px-to-em(5px);
    }
  }

  &__wrapper {
    display: flex;

    @include breakpoint(tablet, below) {
      flex-direction: column;
    }
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    padding: 0.4em px-to-em(40px);
    align-items: center;

    &:first-of-type {
      border-right: 1px solid rgba($tuatara, 0.5);
    }

    @include breakpoint(tablet-landscape, below) {
      padding: px-to-em(20px);
    }

    @include breakpoint(tablet, below) {
      padding: 0 px-to-em(20px) px-to-em(20px);
      &:first-of-type {
        border-right: 0 none;
      }
    }
  }

  &__body-text {
    @include light-weight;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $grey-14;
    max-width: 78%;
    text-align: center;
    margin: 0 auto px-to-em(15px);

    @include breakpoint(tablet, below) {
      font-size: 1rem;
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  &__loginbutton-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: $dark-grey;
    padding-top:px-to-em(10px);
    padding-bottom: px-to-em(5px);
    &--extrapadding {
      padding-top:px-to-em(15px);
    }
    @include breakpoint(tablet, below) {
      padding-top:px-to-em(15px);
      padding-bottom:px-to-em(0px);
      &--extrapadding {
        padding-top:px-to-em(20px);
        padding-bottom:px-to-em(0px);
      }
    }   
  }

  &__insurance-link {
    color:#444;
    text-decoration: underline;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--mobile {
      display: none;
    }

    &--desktop {
      display: inherit;
    }

    @include breakpoint(tablet, below) {
      &--mobile {
        display: block;
      }

      &--desktop {
        display: none;
      }
    }

    button {
      max-width: 78%;
    }

    @include breakpoint(tablet, below) {
      button {
        margin-top: px-to-em(5px);
        max-width: 100%;
      }      
    }
  }
}
