@import '../../../shared-styles/core-variables-and-helpers';

.VerifyUserDetails {
    padding: 0;
    margin-top:px-to-rem(50px);
    &__box {
        max-width: px-to-rem(550px);
        padding: 2.5em;
        margin-top:10px;
        margin: 0 auto;
        padding: px-to-em(40px);
        background-color: rgba($white, 0.93);
        box-shadow: 0 3px 6px $black;
        z-index: 1;
        -webkit-font-smoothing: antialiased;
        @include breakpoint(tablet, below) {
          box-shadow: 0 0 0;
          padding: 1em;
        }
    }

    &__heading {
        @include medium-weight;
        text-align: center;
    }

    &__information {
      font-weight: 400;
      font-size: px-to-rem(18px);
      text-align: center;
    }

    &__label {
      padding-bottom: px-to-rem(20px);
      width: px-to-rem(350px);
      @include breakpoint(tablet, below) {
        width: px-to-rem(290px);
      }
    }

    &__labelText {
      @include bold-weight;
    }

    &__button-wrapper {
        width: px-to-rem(250px);
        padding-bottom: px-to-rem(5px);
        @include breakpoint(tablet, below) {
          button {
            margin-top: px-to-em(5px);
            max-width: 100%;
          }
        }
      }
    &__form-wrapper {
      font-weight: 300;
    }

    &__error {
      color: red;
      @include bold-weight;
    }

    &__password-link {
      color:#444;
      text-decoration: underline;
    }
}
