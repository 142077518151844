@import '../../../shared-styles/core-variables-and-helpers';

.UpgradeConfirmationPage {
    &__confirmation-header {
        color: $grey-14;
        font-weight: normal;
        @include fluid-property('font-size', 30px, 35px);

        @include breakpoint(bootstrap-small, down) {
            margin-top: 1rem;
        }
    }

    &__customer-name {
        font-family: "FrederickSimmsBold", Georgia !important;
        color: $orange;
    }

    &__confirmation-description {
        font-size: 1.2em;
        margin: 1.1em 0 1.7em;
    }

    &__confirmation-details-wrapper {
        display: flex;
        width: 100%;
        margin-bottom: 2em;

        @include breakpoint(bootstrap-small, 'down') {
            flex-direction: column-reverse;
        }
    }

    &__confirmation-image {
        width: 100%;
        object-fit: cover;
    }

    &__confirmation-image-wrapper {
        background-color: $orange;
    }

    &__confirmation-image-wrapper,
    &__confirmation-info-wrapper,
    &__back-button-container {
        width: 50%;

        @include breakpoint(bootstrap-small, 'down') {
            width: 100%
        }
    }

    &__confirmation-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $grey-16;
        padding-top: 1em;
        height: auto;
    }

    &__back-button-container {
        margin-bottom: 2em;
        
        button {
            width: 100%;
            color: $grey-14;
            background-color: $grey-15;
            border-color: $grey-15;
            border-style: solid;
            @include fluid-property('font-size', 18px, 20px);
            padding: 0.5em 0.9em;
            border-radius: 0.2em;
        }
    }
}
