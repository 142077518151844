@mixin fix-inine-block {
	margin-right: -4px;
}

@mixin list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin link-reset {
	text-decoration: none;
	color: inherit;
	transition: color $transition-fast;

	.tabbing &:focus {
		box-shadow: 0 0 3px 3px $form-field-focus;
	}
}

@mixin button-reset {
	@include link-reset;

	display: inline-block;
	padding: 0;

	border: 0;
	border-radius: 0;
	cursor: pointer;
	user-select: none;

	outline: none;
}

@mixin outline-reset {
	.has-hover &:hover {
		outline: none;
	}
	&:focus {
		outline: none;
	}
}

@mixin fieldset-reset {
	margin: 0;
	padding: 0;
	border: 0;
}
