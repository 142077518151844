@import '../shared-styles/core-variables-and-helpers';

.changeAddress {
    &__modal {
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__modal-title {
        background-color: #fff !important;
        margin-bottom:0px !important;
        padding-bottom:0px !important;
    }
    
    &__modal-small-header {
        padding: 12px 62px 0px 62px !important;
        @include breakpoint(mobile-large, down) {
            padding: 12px 15px 0px 15px !important;
        }
    }        

    &__modal-header {
        padding-bottom: 0px !important;
    }
}
