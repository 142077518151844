$black: #222;
$tuatara: #444;
$actual-black: #000;
$white: #fff;
$bootstrap-font-body: #212529;


$orange: #f95108;
$orange-2: #ef6c00;
$orange-3: #ff8623;
$orange-4: #ea6a00;
$orange-5: #ffede5;
$orange-6: #f95108;
$orange-7: #f95b17;
$orange-8: #F9511E;

$amber: #ebb217;

$grey: #4a4a4a;
$grey-2: #9b9b9b;
$grey-3: #d2d2d2;
$grey-4: #f4f4f4;
$grey-5: #e6e6e6;
$grey-6: #bababa;
$grey-7: #606060;
$grey-8: #8a8a8a;
$grey-9: #c0c1c1;
$grey-10: #bfbfbf;
$grey-11: #f9f9f9;
$grey-12: #c0c0c0;
$grey-13: #b3b3b3;
$grey-14: #47474A;
$grey-15: #dbdbdb;
$grey-16: #ededed;
$grey-17: #d0cece;
$grey-18: #808080;
$grey-f8: #f8f8f8;

$title-grey: #595959;

$dark-grey: #444;

$blue: #386c87;
$blue-2: #5d889e;
$blue-3: #ebf1f4;
$blue-4: #6c99ae;
$blue-5: #d2dde2;

$green: #1e9771;
$green-2: #30bf93;
$green-3: #47a94c;
$green-4: #e8f4f1;

$red: #e6231f;
$red-2: #cb0102;
$red-3: #cd2500;
$red-4: #ec2f04;
$red-5: #f0b3bb;

$form-field-valid: $green;
$form-field-default: $grey-2;
$form-field-focus: #03b1ff;
$form-field-invalid: $red;

$facebook-blue: #3b5998;
$twitter-blue: #00aced;
$google-red: #dd4b39;
$youtube-red: #b00;
$linkedin-blue: #007bb6;

$license-plate-yellow: #fef1ad;

$basket-eagle-eye-token-header-blue: #00549e;
$smr-blue: #005FCC;

$shadow-default: #00000026;
