@import '../../../shared-styles/core-variables-and-helpers';

.Brokendown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.87rem !important;
  background-color: #efefef;
  box-shadow: 0 3px 6px rgba($grey-7, 0.3);

  &__header {
    font-size: 2em;
    display: flex;
    justify-content: center;
  }

  &__header-text {
    a {
      color: $orange;
    }
    @include fluid-property('font-size', 20px, 24px);
    @include medium-weight;
    flex: 0 0 100%;
    color: $orange;
    text-align: center;
    padding: 0.25em;

    @include breakpoint(mobile-large, 'below') {
      flex: 1 1 100%;
      padding: 0.25em 0.5em;
    }

    @include ie-11-only() {
      flex: 0 0 250px;
    }
  }

  &__header-icon {
    max-width: 3.125em;

    @include breakpoint(mobile-large, 'below') {
      max-width: 1.4em;
    }

    @include ie-11-only() {
      height: 30px;
    }
  }

  &__body-text {
    p {
      padding: 0 !important;
      margin: 0 !important;  
    }
    @include fluid-property('font-size', 17px, 24px);
    text-align: center;
    color: $tuatara;
  }
}
