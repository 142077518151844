.container {
  display: flex;
  flex-direction: column;
  background-color: #EDEDED;
  padding: 20px;

  .telephoneNumber {
    color: #F95108;
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    .button {
      width: 150px;
    }
  }


}
