.container {
  margin-top: 16px;
  margin-bottom: 60px;
  h3 {
    margin-bottom: 16px;
  }
  ul {
    list-style: none;
    padding: 0;
    font-size: 1rem;
    li {
      padding: 10px;
      border-bottom: 1px solid rgb(200, 200, 200);
      span {
        float: right;
      }
    }
  }
}

.monthlyFlexSummary {
  margin-top: 26px;
  margin-bottom: 42px;
}