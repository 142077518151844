.container {
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
  -webkit-box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 24%);
  -moz-box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 24%);
  box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 24%);
  
  .mainContainer {
    display: flex;
    width: 1190px; // Used same width as the default MyRAC container
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 992px) {
      width: 90%;
    }
    
    .logo {
      width: 72px;
    }

    .navItems {
      .renewalQuery {
        p {
          margin: 0;
          font-size: 1rem;
        }
        i {
          margin-right: 0.5rem;
        }
        .telephoneLabel {
          color: #47474a;
          font-weight: 600;
        }
        .telephoneNo {
          text-decoration: none !important;
          color: #f95108;
          font-weight: 600;
        }
      }
    }
  }
}
