.wrapper {
  display: flex;
  font-family: inherit;
  border: 2px solid #f95108;
  padding-top: 2vw;
  width: 100%;
  height: 470px;
  border-radius: 10px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  button {
    border-radius: 5px;
  }  
  @media only screen and (max-width: 576px) {
    :first-child {
      padding-top: 1vw;
    }
    height: auto;
    margin-bottom: 1rem;
    padding-bottom: 10%;    
  }

  label {
    font-weight: 600;
    line-height: 1.5em;
    small {
      line-height: .5rem;
    }
  }
  input {
    width: 100%;
  }
  button {
    text-align: left !important;
  }
}

.compactVersion {
  input {
    padding: 0.8em;
    font-size: 1rem;
    &::placeholder {
      color: grey;
    }
  }
}