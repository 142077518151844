.Modals {
	@include padding(calc($collapse-padding-small / 2), $collapse-padding-large);

	// Must be 1000 to beat header on old pages, otherwise could be 5.
	z-index: 1000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;

	background: rgba(0, 0, 0, 0.6);

	transition: opacity $transition-fast;

	&__modal {
		display: flex;
		flex: 0 1 auto;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-height: 100vh;
	}

	&--hidden {
		display: none;
		opacity: 0;
	}

	&--closing {
		opacity: 0;
	}

	&--visible {
		opacity: 1;
	}
}
