@import '../../../shared-styles/core-variables-and-helpers';

.InfoBlock {
    background-color: #d6e6f0;
    &__block {
      background-color: #e8f1f7;
      display: flex;      
      border-radius: 2.5px;
      max-width:78%;
      width:100%;
      margin-top: px-to-em(25px);
      margin-bottom: px-to-em(10px);
      padding-top: px-to-em(10px);
      padding-bottom: px-to-em(10px);
      padding-left: px-to-em(20px);
      padding-right: px-to-em(20px);
      @include breakpoint(tablet, below) {
      max-width:100%;
      }
    }
    &__header {
      color: #386C87;
      font-weight:700;
    }
    
    &__description {
      color: #47474A;
    }
    
    &__image {
      padding-right: px-to-em(10px);
    }
 }

 