@import 'src/shared-styles/core-variables-and-helpers';

.siteRibbonOriginal {
  height: 40px;
  line-height: 20px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  font-weight: 600;
  transition: .4s;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.siteRibbonScroll {
  margin-top: -43px;
  animation-name: site_ribbon;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  animation-duration: .4s;
  transition: .4s;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 0.75rem;

  .links {
    display:flex;
    align-items: center;
    margin: 0;
    padding: 0;
    line-height: 1rem;

    .orangeLink {
      a {
        color: $orange-7;
      }
      &:hover {
        color: #fff;
      }
    }

    li {
      min-width: 70px;
      height: 100%;
      border-right: 1px solid #eee;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:first-child {
        border-left: 1px solid #eee;
      }

      &:hover {
        background: #777;
        &:before {
          width: 100%;
          height: 4px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background:$orange-6;
          content: '';
        }
        a {
          color: #fff;
        }
      }

      a {
        padding: 10px;
        color: inherit;
        text-decoration: none!important;
        transition: all .15s ease;
        color: #212529;
      }
    }

    .active {
      background: #777;
      &:before {
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background:$orange-6;
        content: '';
      }
      a {
        color: #fff;
      }
    }
  }

  .rightLinks {
    display: flex;
  }
}
