@import '../../../shared-styles/core-variables-and-helpers';

.textButtonContainer {
  white-space: nowrap;
  border-bottom: 1px solid  #000000;
  display: flex;
  height: 25px;
  cursor: pointer;
  &.orange {
    border-bottom-color: $orange-8;
  }

  &.noUnderline {
    border: none;
  }

  &.textUnderline {
    border: none;
    button {
      text-decoration: underline;
    }
  }

  .iconImage {
    width: 6px;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .button {
    border: 0;
    background-color: transparent;
    font-weight: 600;
    color: #000000;
    &.noUnderline {
      padding: 0;
    }

    &.orange {
      color: $orange-8;
    }
  }
}

.noPadding {
  padding: 0;
}
