@import '../../../shared-styles/core-variables-and-helpers';

.ProductUpgradeCard {
  &__card {
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  &__card-disabled {
    cursor: default;
  }
  &__card-image {
    max-height: 130px;
    object-fit: cover;
  }
  &__card-description p {
    margin: 0;
  }
  &__card-cta {
    text-align: center;
  }
  &__card-title {
    font-size: 1rem;
    color: $grey-14;
  }
  &__card-title-price {
    color: $orange;
  }
}
