@import '../../../shared-styles/core-variables-and-helpers';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hpp_iframe {
  div:first-child {
    padding-top: 1vw;
  }
  padding-bottom: 20px;
  font-family: $base-font-family !important;
  border: 2px solid #f95108;
  border-radius: 10px;
  width: 100%;
  height: 545px;
  overflow: hidden;
  @media only screen and (max-width: 576px) {
    height: 32rem;
  }
}

.discountApplied {
  color: #f95108;
  font-size: 1rem;
}

.paymentImage {
  max-width: 435px;
  padding: 24px 15px 0;
  margin: 0 auto;
}
