@import '../../../shared-styles/core-variables-and-helpers';

.Faqs {
  padding: 0;

  &__box {
    max-width: 1145px;
    padding: 2.5em;
    margin: 0 auto;
  }

  &__heading {
    color: $grey-14;

    &--main {
      text-align: left;
    }

    &--sub {
      @include breakpoint(tablet, down) {
        font-size: 20px;
      }
      font-size: 26px;
      font-weight: 600;
      line-height: 37px;
      margin-bottom: 1rem !important;
    }
  }

  &__faq-stack {
    @include breakpoint(tablet, down) {
      margin-top: 1rem;
      margin-right: 1rem;
    }
    color: $tuatara;
    margin-top: 3rem;
    border-bottom: 1px solid #d9d9d9;
  }

  &__faq {
    position: relative;
    display: inherit;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    border-top: 1px solid #d9d9d9;
  }

  &__faq-question {
    width: 100%;
    color: $tuatara;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    padding-left: 0;
    background-color: transparent;
    border: 0 none;
    outline: none;

    @include breakpoint(desktop, up) {
      // Hover effect only applicable for desktop
      &:hover {
        i {
          color: $orange;
        }
      }
    }

    @include breakpoint(tablet, down) {
      // Default font size too big for mobile therefore reduced for better readability
      font-size: 16px;
      i {
        font-size: 1rem !important;
      }
    }

    i {
      font-size: 1.5rem;
    }

    &:active {
      background-color: transparent;
    }
  }

  &__faq-question-title {
    @include breakpoint(tablet, down) {
      line-height: normal;
      padding: 15px;
    }
    width: 96%;
    color: $tuatara;
    margin: 0;
    color: inherit;
    font-weight: 600;
    line-height: 4rem;
  }

  &__faq-answer {
    padding: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #333;
    cursor: default !important;
  }

  &__orange {
    color: $orange;
  }
}
