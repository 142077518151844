.PageNotFound {
    &__header {
        color: #47474a;
        font-weight: normal;
        text-transform: none !important;
        font-size: 2rem;
        @media only screen and (max-width: 576px) {
            margin-top: 1rem;
        }
    }

    &__details-wrapper {
        display: flex;
        width: 100%;
        margin: 2em 0 2em 0;
        font-size: 1rem;
        @media only screen and (max-width: 576px) {
            flex-direction: column-reverse;
            margin: 1em 0 1em 0;
        }
    }

    &__image {
        display: flex;
        position: absolute;
        top: 12rem;
        margin-left: 90px;
        width: 350px;
        height: 350px;
        background: url('https://images.contentstack.io/v3/assets/bltab270fecbe9695fb/blt080210fb599f5f01/62582c9b02d8144fd4dab6a0/Maintenance_Desktop.png') no-repeat;
        @media only screen and (max-width: 576px) {
            margin-left: -0.75rem;
            top: 17rem;
            padding-right: 20px;
            height: 350px;
        }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            top: 200px;
            right: 100px;
        }
    }

    &__image-wrapper {
        background-color: #ededed;
        border-right: 210px solid #fff;
        @media only screen and (max-width: 576px) {
            position: relative;
            display: flex;
            width: 100%;
            background-size: 100%;
        }
    }

    &__image-wrapper,
    &__info-wrapper,
    &__back-button-container {
        width: 50%;
        @media only screen and (max-width: 576px) {
            width: 100%;
        }
      span {
        color: #f95108;
        font-size: larger;
      }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #ededed;
        padding: 30px;
        height: auto;
        @media only screen and (max-width: 576px) {
            border-bottom: 300px solid #ededed;
        }
    }

    &__back-button-container {
        margin-bottom: 2em;
        button {
            text-align: left;
            padding: 1rem;
            border-radius: 0.2em;
        }
    }
}
