@font-face {
    font-family: 'myrac';
    src:  url('../fonts/myrac.eot?iwc42p');
    src:  url('../fonts/myrac.eot?iwc42p#iefix') format('embedded-opentype'),
    url('../fonts/myrac.ttf?iwc42p') format('truetype'),
    url('../fonts/myrac.woff?iwc42p') format('woff'),
    url('../fonts/myrac.svg?iwc42p#myrac') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="myrac-icon-"], [class*=" myrac-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'myrac' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.myrac-icon-white-ellipse:before {
    content: "\e900";
    color: #ececec;
}
.myrac-icon-RedEllipse:before {
    content: "\e901";
    color: #f95108;
}
.myrac-icon-two-human:before {
    content: "\e902";
    color: #47474a;
}
.myrac-icon-insurance-doc-add:before {
    content: "\e903";
    color: #47474a;
}
.myrac-icon-insurance-doc:before {
    content: "\e904";
    color: #47474a;
}
.myrac-icon-green-bar:before {
    content: "\e905";
    color: #1e9771;
}
.myrac-icon-yellow-bar:before {
    content: "\e906";
    color: #ffd13b;
}
.myrac-icon-mot:before {
    content: "\e907";
    color: #47474a;
}
.myrac-icon-vehicle-tax:before {
    content: "\e908";
    color: #47474a;
}
.myrac-icon-wrench:before {
    content: "\e909";
    color: #47474a;
}
.myrac-icon-refresh:before {
    content: "\e90a";
    color: #47474a;
}
.myrac-icon-calendar:before {
    content: "\e90b";
    color: #47474a;
}
.myrac-icon-switch-on .path1:before {
    content: "\e90c";
    color: rgb(190, 190, 190);
    opacity: 0.194;
}
.myrac-icon-switch-on .path2:before {
    content: "\e90d";
    margin-left: -2em;
    color: rgb(249, 81, 8);
}
.myrac-icon-switch-off .path1:before {
    content: "\e90e";
    color: rgb(190, 190, 190);
    opacity: 0.194;
}
.myrac-icon-switch-off .path2:before {
    content: "\e90f";
    margin-left: -2em;
    color: rgb(71, 71, 74);
    opacity: 0.5;
}
.myrac-icon-circle-add .path1:before {
    content: "\e910";
    color: rgb(236, 236, 236);
}
.myrac-icon-circle-add .path2:before {
    content: "\e911";
    margin-left: -1.1455078125em;
    color: none;
}
.myrac-icon-circle-add .path3:before {
    content: "\e912";
    margin-left: -1.1455078125em;
    color: rgb(190, 190, 190);
}
.myrac-icon-checked:before {
    content: "\e913";
    color: #1e9771;
}
.myrac-icon-add:before {
    content: "\e914";
    color: #1e9771;
}
.myrac-icon-cancel:before {
    content: "\e915";
    color: rgba(71, 71, 74, 0.5);
}
.myrac-icon-grey-bar:before {
    content: "\e916";
}
.myrac-icon-question-mark:before {
    content: "\e917";
    color: #47474a;
}
.myrac-icon-grey-add:before {
    content: "\e918";
    color: #bebebe;
}
.myrac-icon-empty:before {
    content: "\e919";
}
.myrac-icon-RAC_search_icon:before {
    content: "\e91a";
    color: #47474a;
}
