.RadioButtons {
	$c: &;
	display: flex;
	position: relative;

	&__input {
		position: absolute;
		visibility: hidden;
	}

	&__label {
		@include medium-weight;

		padding: 0.25em 0.5em;
		background: rgba(0, 0, 0, 0.3);
		color: $grey-2;

		cursor: pointer;

		&:first-of-type {
			border-top-left-radius: 0.25em;
			border-bottom-left-radius: 0.25em;
		}

		&:last-of-type {
			border-top-right-radius: 0.25em;
			border-bottom-right-radius: 0.25em;
		}
	}

	&__input:checked + &__label {
		background: rgba(255, 255, 255, 0.3);
		color: white;
	}

	&__input:disabled + &__label {
		cursor: not-allowed;
		opacity: 0.6;
	}

	.tabbing &__input:focus + &__label {
		box-shadow: 0 0 0 3px $form-field-focus;
	}

	&--large {
		$shadow-colour: #9e9b9b;
		#{$c}__label {
			background: transparent;
			font-size: px-to-rem(16px);
			padding: 10px 35px;
			user-select: none;

			&:first-of-type,
			&:last-of-type {
				border-radius: 0;
			}

			&:first-of-type {
				box-shadow: inset 1px 1px 2px 1px $shadow-colour;
			}

			&:last-of-type {
				box-shadow: inset -1px 1px 2px 1px $shadow-colour;
			}
		}
		#{$c}__input:checked + #{$c}__label {
			background: $grey-3;
			color: $black;
			box-shadow: none;
		}
	}
}
