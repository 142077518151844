.container {
  display: flex;
  width: 100%;
  height: calc(80vh - 182px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    font-size: 2em;
  }
  .text {
    font-size: 1em;
  }
  .buttonContainer {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .button {
      width: 200px;
    }
  }

}
