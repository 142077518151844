.headerPlaceHolder {
  width: 100%;
  height: 125px;
  display: block;

  @media only screen and (max-width: 795px) {
    height: 60px;
  }
}

.header {
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  transition: all .35s ease;
}

.affix {
  background-color: rgba(255,255,255,.95);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  position: fixed;
  top: 0;
  z-index: 999;
  margin-top: -40px;
  animation-name: site_ribbon;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  animation-duration: .4s;
  transition: .4s;

  @media only screen and (max-width: 795px) {
    margin-top: 0px;
  }
}

.affixTop {
  position: relative;
}
