@import '../../../shared-styles/core-variables-and-helpers';

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  .infoContainer {
    @media only screen and (max-width: 1060px) {
      min-width: 142px;
    }

    @media only screen and (max-width: 440px) {
      min-width: 50%;
    }

    p {
      margin: 0;
    }
    .title {
      font-weight: 600;
    }
    .dateReminderText {
      color: $orange-6; // Check this is the correct colour.
    }
  }
}
