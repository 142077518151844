/* Webfont: FrederickSimms-Regular */
@font-face {
    font-family: 'FrederickSimms';
    src: url('../../assets/fonts/FrederickSimmsRegular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: FrederickSimms-Bold */
@font-face {
    font-family: 'FrederickSimmsBold';
    src: url('../../assets/fonts/FrederickSimmsBold.woff') format('woff');
    font-style: 500;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: FrederickSimms-Display */
@font-face {
    font-family: 'FrederickSimmsDisplay';
    src: url('./../../assets/fonts/FrederickSimmsDisplay.woff2') format('woff2'),
        url('./../../assets/fonts/FrederickSimmsDisplay.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
