.BtnGroup {
	@include clearfix;

	&__btn {
		display: block;
		float: left;
	}

	&__btn + &__btn {
		margin-left: 0.5em;
	}
}
