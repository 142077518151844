.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
  .title {
    font-size: 2.2em;
    @media only screen and (max-width: 600px) {
      text-align: center;
    }
  }
  .priceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
    .price {
      font-size: 3em;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .image {
      width: 11em;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .greyContainer {
    background-color: #EDEDED;
  }

  .paymentIframeContainer {
    margin: 20px 0px;
  }
}

