@import '../../../shared-styles/core-variables-and-helpers';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hpp_iframe {
  font-family: $base-font-family !important;
  border: 2px solid #f95108;
  border-radius: 10px;
  padding-top: 2vw;
  width: 100%;
  height: 470px;
  overflow: hidden;
  @media only screen and (max-width: 576px) {
    height: 26rem;
  }
}
