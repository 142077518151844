@import '../../../shared-styles/core-variables-and-helpers';

.PaymentFailedPage {
    &__errorPage-header {
        color: $grey-14;
        font-weight: normal;
        text-transform: none !important;
        @include fluid-property('font-size', 28px, 33px);

        @include breakpoint(tablet, down) {
            font-size: 2.2rem !important;
            margin-top: 1rem;
        }
    }

    &__errorPage-details-wrapper {
        display: flex;
        width: 100%;
        margin: 2em 0 2em 0;
        font-size: 1rem;

        @include breakpoint(tablet, down) {
            flex-direction: column-reverse;
            margin: 1em 0 1em 0;
        }
    }

    &__errorPage-image {
        display: flex;
        position: absolute;
        top: 175px;
        margin-left: 90px;
        width: 350px;
        height: 350px;
        background: url('https://images.contentstack.io/v3/assets/bltab270fecbe9695fb/blt080210fb599f5f01/62582c9b02d8144fd4dab6a0/Maintenance_Desktop.png') no-repeat;

        @include breakpoint(tablet, down) {
            margin-left: 0;
            height: 350px;
        }
    }

    &__errorPage-image-wrapper {
        background-color: $grey-16;
        border-right: 300px solid $white;

        @include breakpoint(tablet, down) {
            position: relative;
            display: flex;
            width: 100%;
            background-size: 100%;
        }        
    }

    &__errorPage-image-wrapper,
    &__errorPage-info-wrapper,
    &__back-button-container {
        width: 50%;

        @include breakpoint(tablet, down) {
            width: 100%;
        }
    }

    &__errorPage-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $grey-16;
        padding: 30px;
        height: auto;
        border-bottom: 60px solid $grey-16;

        @include breakpoint(tablet, down) {        
            border-bottom: 300px solid $grey-16;
        }
    }

    &__back-button-container {
        margin-bottom: 2em;
        
        button {
            width: 100%;
            color: $white;
            background-color: $orange;
            border-color: $white;
            border-style: solid;
            @include fluid-property('font-size', 18px, 20px);
            padding: 0.5em 0.9em;
            border-radius: 0.2em;
        }

        @include breakpoint(tablet, down) {
            display: inline-block;
        }
    }
}