@import '../../../shared-styles/core-variables-and-helpers';

.mot-history-modal {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background: rgba(71, 71, 74, 0.5);

    &__btn-transparent {
        background-color: transparent;
        color: #ffffff !important;
        text-decoration: none !important;

        img {
            margin-right: 5px;
        }
    }

    &__emphasis {
        font-weight: 600;
    }

    &__dialog {
        top: 50%;
        transform: translate(0, -50%) !important; // needed to override bootstrap transform property to fit particular modal type in center of page
        pointer-events: initial !important;

        @include breakpoint(tablet, up) {
            position: fixed !important;
            min-width: 576px;
            left: 50%;
            top: 50%;
            margin: 0;
            transform: translate(-50%, -50%) !important;  //needed to override bootstrap
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__content {
         box-shadow: 0 3px 6px #00000029;
         background: #fff;
         color: #333;
     }

    &__header {
        background: #47474A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        @include breakpoint(mobile-large, below) {
            align-items: flex-start;
        }

        h4 {
            color: #fff;
            font-size: 1.6em;
            padding-top: 0.5rem;

            @include breakpoint(mobile-large, below) {
                font-size: inherit;
            }
        }
    }

    &__reg-outer {
        text-align: center;
    }

    &__reg {
        text-transform: uppercase;
        margin-left: 0;
        text-align: center;
    }

    &__header-center {
        display: flex;
        flex-grow: 1;

        h4 {
            flex-grow: 1;
        }

        @include breakpoint(tablet, below) {
            flex-direction: column;
        }
    }

    &__result-outer {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        @include breakpoint(tablet, below) {
            justify-content: center;
        }
    }

    &__result-pass, &__result-fail {
        padding: 10px 20px;
        color: #fff;
        text-transform: uppercase;
        background: purple;
        text-align: center;
    }

    &__result-pass {
        background: #1E9771;
    }

    &__result-fail {
        background: #E6231F;
    }

    &__close {
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
    }

    &__inner{
        padding: 20px;
        font-size: inherit;
        line-height: normal;

        button {
            text-decoration: none;
            font-weight: 600;
        }

        @include breakpoint(mobile-large, below) {
            padding: 10px;
            font-size: 0.9em;
        }
    }

    &__inner-breakdown{
        max-height: 500px;
        overflow: hidden;
    }

    &__summary:not(:last-child) {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    &__summary {

        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
            }
        }

        &__inner-info > * {
            display: inline-block;
            float: left;
            font-size: inherit;
        }

        &__inner-info > p {
            font-weight: 600;
            margin: 0;
        }

        &__inner-info > *:not(:first-child) {
            margin-left: 10px;
        }
    }

    &__row {
        padding: 10px 20px;

        hr {
            margin: 5px 0;
        }
    }

    &__row img + p{
        display: initial;
        margin-left: 10px;
    }

    &__row-result-pass {
        background: #D1EBE3;
    }

    &__row-result-fail {
        background: #FAD4D1;
    }

    &__row-fail, &__row-dangerous, &__row-major {
        background: #FAD4D1;
        margin-bottom: 5px;

        @include breakpoint(mobile-large, below) {
            margin-left: 0;
            margin-right: 0;
            padding: 10px 0;
        }
    }

    &__row-advisory {
        background: #DBDBDB;
        margin-bottom: 5px;

        @include breakpoint(mobile-large, below) {
            margin-left: 0;
            margin-right: 0;
            padding: 10px 0;
        }
    }

    &__row-minor {
        background: #FFDBCF;
        margin-bottom: 5px;

        @include breakpoint(mobile-large, below) {
            margin-left: 0;
            margin-right: 0;
            padding: 10px 0;
        }
    }

    &__row-prs {
        background: transparent;
        margin-bottom: 5px;

        @include breakpoint(mobile-large, below) {
            margin-left: 0;
            margin-right: 0;
            padding: 10px 0;
        }
    }

    &__mot-notes {
        border-top: 1px #d3d3d3 solid;
    }

    &__error-text {
        padding: 30px;
        font-size: inherit;
    }

    &__error-text > p {
        text-align: center !important;
        max-width: 450px;
        float: none;
        margin: 0 auto;
    }
}


