@mixin button-reset {
	@include link-reset;

	display: inline-block;

	border: 0;
	border-radius: 0;

	cursor: pointer;
	user-select: none;

	outline: none;
}
