@import '../../shared-styles/core-variables-and-helpers';

.welcome {
    &__container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5em;

        @include breakpoint(bootstrap-small, down) {
            margin-top: 1.2em;
            height: auto;
        }
    }

    &__loading {
        text-align: center;
    }

    &__welcome-header {
        color: $grey;
        font-size: 1.75rem;
        margin-bottom: 0 !important;
        @include breakpoint(bootstrap-small, down){
            display: none;
        }
    }

    &__welcome-mobile-wrapper {
        display: none;

        @include breakpoint(bootstrap-small, down) {
            display: block;
        }
    }

    &__hi-mobile {
        margin: 0.3em 0 0.09em;
    }

    &__welcome-to-text {
        font-weight: 600;
        color: $grey;
        margin: 0 0 0.5em;
    }

    &__member-no {
      color: $orange;
      font-weight: 600;
        letter-spacing: 1px;
        @include breakpoint(bootstrap-small, down) {
            color: $orange
        }
    }

    &__button-wrapper {
      display: flex;
      justify-content: space-between;

      @include breakpoint(bootstrap-small, down) {
        display: none;
      }
    }

    &__edit-profile-icon-wrapper {
        width: 1.7em;
        height: 1.7em;
        margin-top: 0.3em;
    }

    &__edit-profile-icon {
        width: 100%;
        height: 100%;
    }

    &__edit-wrapper {
        display: flex;
        align-items: flex-end;
        padding-bottom: 0.6em;
        margin-left: 0.7em;

        @include breakpoint(bootstrap-small, down) {
            align-items: flex-start;
        }
    }

    &__breakdown-profile-icon-wrapper {
      width: 1.7em;
      height: 1.7em;
      margin-top: 0.3em;

      @include breakpoint(bootstrap-small, down) {
        width: 1.4em;
        height: 1.4em;
        margin-top: 0;
      }
    }

    &__breakdown-wrapper {
      padding-bottom: 0.6em;
      margin-left: 0.7em;
      margin-top: auto;
      margin-right: 56px;

      @include breakpoint(bootstrap-small, down) {
        margin-top: -8px;
        margin-right: 0;
      }

      a {
        color: black;
        display: flex;

        @include breakpoint(bootstrap-small, down) {
          margin-top: 3px;
          align-items: center;
          flex-direction: column;
        }
      }
    }

    &__breakdown-text {
      margin-left: 0.5em;
      margin-bottom: 0.2em;
      margin-top: auto;
      color: $grey;
      br {
        display: none;
      }

      @include breakpoint(bootstrap-small, down) {
        font-size: 10px;
        font-weight: 300;
        line-height: 10px;
        text-align: center;
        margin-top: 3px;
        color: $grey;
        br {
          display: inherit;
        }
      }

      a {
        margin-bottom: 0;
        color: $grey;
      }
    }

    &__edit-text {
        margin-left: 0.5em;
        margin-bottom: 0.2em;

        @include breakpoint(bootstrap-small, down) {
            display: none;
        }

        a {
            margin-bottom: 0;
            color: $grey;
        }
    }
}
