.myrac__checkbox--label {
    cursor: pointer;
}

.checkbox--verticalAlign {
    vertical-align: super;
}

.myrac__checkbox--input:checked + .myrac__checkbox--span:before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAaCAYAAABCfffNAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAFKSURBVEhL7ZQxSsRQEIa9gkfwAHsAD+AZPIWwnY2VjY21iCBbaGenlnaCtiJbylYiLAiyCyIhkJEvvJGX9yYTzcZGHPiLTYb58v55/66tH4/lt/UPMbV5vi8bp7vZ88Egk8dboabzl+zdIJDtyyOpqqqGUJwofr8yBHvm78swXuT66SHrWRnCUC1gg+9k5+YsjJfaLmyz+npD8H1RfASE1Iu3+lBvyN3zLIwXmb29mjapekEO7q/CeJGyLGXr4tDsU/0YwkAGawG0+mI1INwUbggLjZ+rsARrtLDM6kv1BeELtYqiMC3QVFMsPQ1dmxon4S9BK11mmuq201pqQOJryUBN7+hkrzPVnrLFxwGj+P2dVHvKICj2PrbIS7UnE8KXxvvR8lLtyYSg9G+jK9WeWiGIfRA8YF2p9uRCEDer7wlUnZAh9FcgY/kE+CrysQOeRXYAAAAASUVORK5CYII=
    ) no-repeat;
    width: 23px;
    height: 23px;
}
.cover-options .row.yes .live::after {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAaCAYAAABCfffNAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAFKSURBVEhL7ZQxSsRQEIa9gkfwAHsAD+AZPIWwnY2VjY21iCBbaGenlnaCtiJbylYiLAiyCyIhkJEvvJGX9yYTzcZGHPiLTYb58v55/66tH4/lt/UPMbV5vi8bp7vZ88Egk8dboabzl+zdIJDtyyOpqqqGUJwofr8yBHvm78swXuT66SHrWRnCUC1gg+9k5+YsjJfaLmyz+npD8H1RfASE1Iu3+lBvyN3zLIwXmb29mjapekEO7q/CeJGyLGXr4tDsU/0YwkAGawG0+mI1INwUbggLjZ+rsARrtLDM6kv1BeELtYqiMC3QVFMsPQ1dmxon4S9BK11mmuq201pqQOJryUBN7+hkrzPVnrLFxwGj+P2dVHvKICj2PrbIS7UnE8KXxvvR8lLtyYSg9G+jK9WeWiGIfRA8YF2p9uRCEDer7wlUnZAh9FcgY/kE+CrysQOeRXYAAAAASUVORK5CYII=
    ) 0 no-repeat;
}

.link {
    color: $orange-4;
}
.cover-options.complimentary .row .col-12::after{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAbCAYAAABm409WAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAGOSURBVEhL7ZUxa8JAFMf7+YJkqJAhSwYHFQL5BIIdMgh2yDfI7CQIbi4u8QNY7FKt1cUhdjDgEPBf3vVOvOSS2CQdCn3wH8y9+//ie/cuD5qm4Tf1DyhUrQDLsqDruvSsNsBoNALFev0mQWoB9Ho9XC4XBqCgfyLWKgMMw0AYhtwaCIJAWq8MIEMRn8cjA96uVwJ4nsetwUpEpUrmlAa0Wi2cz2duD0wmE2VeKUCj0cDqdcWtgd3uA83mozK3FMD3fW4NxHEMx3GUeaQfA8iMTEUQTJUnJAHm8zkOhwNc15WShKgM+/2eWwPLlyUrlypX6ArodDp8G1jzut1uKpkaKSKKImmgsnQF0JtsNmu+Pd24p35fmtbn4fC6liepRO12Wzp6s9mMPTdNkw2RCCrl7b48pZpMb3YbNEyLxYL/ArsWktOapxSANJ1Oud33MRSRNa15UgKo9nTtJmM8Hqdyi6QEkJL92G7fUx+Te5QJIA0GA1ai6HSCbdvKnCLlAkh0grLumXtUCKiqvw7Q8AWYUmwssWyr+wAAAABJRU5ErkJggg==
    ) 0 no-repeat;
}

.tile {
    p.car {
        white-space: inherit;
    }

    .action.edit button::before {
        content: '';
        display: inline-block;
        width: 22px;
        height: 24px;
        margin-right: 6px;
        margin-bottom: -6px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAYAAAD+vg1LAAAACXBIWXMAAAsSAAALEgHS3X78AAAAvElEQVRIx+XVyw3CMAyA4R/EAGSDjpAVMkrvvXQCxAQVUgZgBDZgBTaADdoRuLhSaBtQ7VwASznk9TmKYgW+LTbajX3DHuik27rIYIYFvQKVDD2AkOJbA+qBExAkQZeu2xlOegQOyfSggidocJFb34DgZxdpV99xBvUydnGRevWr0KAfYS36FragWdiKLsIl0BlcCn2BS6LTkvbSxkRqNFd548nRokt33AuMlGnN30T6KirgbsFc1P9IPxxPJDBmp+iXQaoAAAAASUVORK5CYII=) 50% no-repeat;
    }

    .action button.link {
        text-decoration: none;
        font-weight: 600 !important;
    }

    .action.normal {
        text-align: inherit;
        padding: 0;
        bottom: initial;
        right: initial;
        position: initial;
    }
}

.tile_2 {
    .action.edit button::before {
        content: '';
        display: inline-block;
        width: 22px;
        height: 24px;
        margin-right: 6px;
        margin-bottom: -6px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAYAAAD+vg1LAAAACXBIWXMAAAsSAAALEgHS3X78AAAAvElEQVRIx+XVyw3CMAyA4R/EAGSDjpAVMkrvvXQCxAQVUgZgBDZgBTaADdoRuLhSaBtQ7VwASznk9TmKYgW+LTbajX3DHuik27rIYIYFvQKVDD2AkOJbA+qBExAkQZeu2xlOegQOyfSggidocJFb34DgZxdpV99xBvUydnGRevWr0KAfYS36FragWdiKLsIl0BlcCn2BS6LTkvbSxkRqNFd548nRokt33AuMlGnN30T6KirgbsFc1P9IPxxPJDBmp+iXQaoAAAAASUVORK5CYII=) 50% no-repeat;
    }

    .action button.link {
        text-decoration: none;
    }
}

.margin-side{
    margin:0 10px;
}
