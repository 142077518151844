@import '../../../shared-styles/core-variables-and-helpers';

.MembershipCard {
  &__card {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    border: none;
    background-color: $orange;
    color: $white;
    max-width: 420px; // 😏
    min-height: 240px;
  }
  &__card-expired {
    background-color: $grey-18;
  }
  &__image {
    width: 80px;
    object-fit: cover;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 5px
  }
  &__subtitle {
    font-size: 1.1rem;
    line-height: 1.1rem;
    min-height: 3rem
  }
  &__listing-title {
    font-size: 1rem;
  }
  &__listing-content {
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 700;
  }
}
