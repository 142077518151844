$container-max-width-desktop: 1260px;
$basket-header-desktop-top-padding: 20px;
$basket-header-desktop-bottom-padding: 15px;


$chat-now-right: $container-max-width-desktop + ($collapse-padding-large * 2);
$chat-now-border-radius: 7px;

.ChatNow {
	// Only render if chat now is not blocked by an adBlocker
	&:not([href]) {
		display: none;
	}

	@include link-reset;
	@include medium-weight;

	@include breakpoint($tablet, down) {
		display: none;
	}

	@include breakpoint($chat-now-right, up) {
		right: calc(50vw - #{calc($chat-now-right / 2)});
	}

	@include breakpoint($chat-now-right, below) {
		@include margin-right($clip-at-end: false);

		right: 0;
	}

	position: fixed;
	bottom: 0;

	width: 200px;
	height: 45px;

	background: $blue;
	color: $white;

	font-size: 21px;

	border-radius: $chat-now-border-radius $chat-now-border-radius 0 0;

	transition: transform $transition-fast, opacity $transition-fast;

	&--hide {
		transform: translateY(100%);
		opacity: 0;
	}

	&__inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		white-space: nowrap;

		svg {
			transform: translateY(0.15em);
			margin-right: 0.1em;
			width: 1.1em;
			height: 1.1em;
			fill: currentColor;
		}
	}

	img {
		display: none;
	}

	.has-hover &:hover {
		color: $white;
	}

	&:focus {
		outline: none;
	}
}
