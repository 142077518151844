@import 'shared-styles/core-variables-and-helpers';

.errorScreen {
  max-width: 800px;
  padding: 15px 13px;
  margin: 0 auto;
  border-radius: 6px;
  border: 2px solid $red;
  box-shadow: 0px 2px 10px 0px $shadow-default;
  font: 700 0.9rem / 1.25rem $base-font-family;
  text-align: center;

  img {
    margin-bottom: 10px;
  }

  h2 {
    font: inherit;
    color: $red;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
    color: $grey-14;
  }

  a {
    text-decoration-line: underline;
    color: $orange;
  }

  &.closed {
    border: 2px solid $orange;

    h2 {
      color: $orange;
    }
  }
}