.container {
  max-width: 1190px !important;
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 225px);
  margin: 0 auto;
  padding-right: 0.75rem;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: 0.75rem;
  padding-left: var(--bs-gutter-x,.75rem);
  @media only screen and (max-width: 576px) {
    width: 96%;
  }
}