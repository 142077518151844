.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
  .noteContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 90%;
    h3 {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 15px;
      font-weight: 600;
      color: #47474A;
    }
    .subHeading {
      width: 100%;
    }

    p {
      width: 100%;
    }
  }
  .image {
    max-width: 30%;
    @media only screen and (max-width: 600px) {
      margin: 0px 10px;
    }
  }
}
