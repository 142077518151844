@import 'shared-styles/core-variables-and-helpers';

.termsAndConditions {
  padding: 10px 26px;
  font: 400 0.9rem / 1.25rem $base-font-family;

  a {
    word-break: break-all;
    text-decoration: underline;
    color: $blue;
  }

  h4 {
    margin-bottom: 0;
    font: 600 0.9rem / 1.25rem $base-font-family;
  }

  ol {
    padding-left: 1.5rem;
  }
}
