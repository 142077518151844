@import 'shared-styles/core-variables-and-helpers';

.header {
  display: block;
  margin: 0 auto;

  @media only screen and (max-width: 576px) {
    width: 90% !important;
    padding-top: 5vw;
  }

  .title {
    font-size: 2rem;
  }

  .price {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
}

.container {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 1vw;
  padding-bottom: 2rem;
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    width: 100%;
  }

  .iframe, .info {
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    font-size: 1rem;
    @media only screen and (max-width: 576px) {
      width: 90% !important;
    }
  }

  .iframe {
    width: 40%;
    overflow: hidden;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    @media only screen and (max-width: 576px) {
      margin-bottom: 6vw;
    }
  }

  .info {
    width: 60%;
    padding-left: 30px;
    @media only screen and (max-width: 576px) {
      padding-left: 0;
      margin-bottom: 4vw;
    }

    .greybox {
      height: 545px;
      background-color: $grey-f8;
      padding: 25px;
      border-radius: 10px;
      @media only screen and (max-width: 576px) {
        height: auto;        
        div {
          width: 50%;
        }
      }
      
      div {
        width: 25%;
      }
      b {
        color: #f95108;
        line-height: 1.5rem;
      }
    }    
  }
  
  .clear {
    clear: both;
  }
}

